import axios, { Method, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ERROR_MESSAGES } from './constants';
import { SomeZodObject } from 'zod';
import { zodErrorSchema, ServerError, serverErrorSchema } from './schema'
import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import urljoin from 'url-join';

function zodCatch(e: any, errorCallback: Function) {
    // Is it a Zod Error?
    const zodErrorResult = zodErrorSchema.safeParse(e);
    if(zodErrorResult.success) {
      // It is a Zod Error
      console.log(zodErrorResult.data);
      return errorCallback({ message: "Application was unable to process this request" });
    }
    // Other, unparseable error
    console.log(e);
    return errorCallback(e);
}

function asServerError(e: any): ServerError {
  const serverErrorResult: ServerError = serverErrorSchema.parse(e.response.data);
  serverErrorResult.name = serverErrorResult.type;
  serverErrorResult.message = ERROR_MESSAGES[serverErrorResult.type];
  return serverErrorResult;
} 

export const parseResponseWithSchema = <T extends { [x: string]: any; }>(response: AxiosResponse, schema: SomeZodObject, errorCallback: Function, isArray: boolean): T => {
  try {
    if (isArray === true) return response.data.map((entity: any) => schema.parse(entity)) as T;
    else return schema.parse(response.data) as T;
  }
  catch(e: any) {
    return zodCatch(e, errorCallback);
  }
}

export const createAsyncThunkByType = <TRes extends { [x: string]: any; }, TReqDto>(
  verb: Method, path: string, 
  responseSchema: SomeZodObject, isArray: boolean = false, 
  requestSchema?: SomeZodObject, 
  configOverride?: AxiosRequestConfig,
  actionPathOverride?: string
): AsyncThunk<TRes, TReqDto, { rejectValue: Error }> => 
{
  const action: string = verb.toString() + ':' + (actionPathOverride ?? path);
  return createAsyncThunk<TRes, TReqDto, { rejectValue: Error }>(action, async (payload, {rejectWithValue }) => {
      const dto = requestSchema !== undefined ? requestSchema?.parse(payload) : payload;
      const config = {
        method: verb,
        url: urljoin('/api/', path),
        data: dto,
        ...configOverride
      };
      try {
        const response = await axios(config);
        const parsed: TRes = parseResponseWithSchema<TRes>(response, responseSchema, rejectWithValue, isArray);
        return parsed;
      }
      catch(e: any) {
        const serverErrorResult: ServerError = asServerError(e);
        console.log(serverErrorResult);
        return rejectWithValue(serverErrorResult);
      }
    }
  );
}