import { useState } from 'react';
import { useStyletron } from 'baseui';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../../redux/hooks';
import { Spinner } from 'baseui/spinner';
import { createAttachment, deleteAttachment, clearState } from '../../slices/attachment/attachment.slice';
import { SIZE } from '../../../shared/styles'; 
import { FlexGrid } from '../../../shared/components/layout/FlexGrid';
import { AsyncStatus } from '../../../shared/constants';
import { FormCard as Card } from '../../../shared/components/elements/Card';
import Title from '../../../shared/components/elements/Title';
import FileUploader from '../../../shared/components/elements/FileUploader';
import { NotificationModal } from '../../../shared/components/elements/Modal';
import Badge from '../../../shared/components/elements/Badge';
import { CreateAttachmentResponseDTO } from 'unity-types';
import { type DropFilesEventHandler } from 'baseui/file-uploader';

const SingleAttachmentUploadForm = ({ title, type }: { title: string, type: 'Receipt' | 'W-9' }) => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  const createAttachmentResponse: CreateAttachmentResponseDTO | null = useSelector(state => state.reentry_portal.rp_attachments.createAttachmentResponse);
  const status: AsyncStatus = useSelector(state => state.reentry_portal.rp_attachments.status);
  const error: string | null = useSelector(state => state.reentry_portal.rp_attachments.error);
  const [uploadFileError, setUploadFileError] = useState<string>();

  const onDrop: DropFilesEventHandler = (accepted, rejected) => {
    if(rejected.length > 0) {
      setUploadFileError(`The file you're trying to upload has an invalid file type.`);
    } else if (accepted.length > 0) {
      dispatch(createAttachment({ file: accepted[0], type }));
    }
  }

  const onDeleteClick = () => {
    dispatch(deleteAttachment(createAttachmentResponse!));
  }

  return (
    <>
      <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px' }}>
        <FlexGrid>
          <Title title={title} size={SIZE.LARGE} overrides={{ marginRight: '10px' }}/>
          {status === AsyncStatus.Loading && <Spinner />}
        </FlexGrid>
        {createAttachmentResponse && <Badge content={createAttachmentResponse.filename} onDeleteClick={onDeleteClick}/>}
        <FileUploader onDrop={onDrop} accept={'application/pdf, image/png, image/jpeg'} />
      </Card>
      <NotificationModal 
        isOpen={!!uploadFileError} 
        message={uploadFileError} 
        onClose={() => setUploadFileError(undefined)}
      />
      <NotificationModal
        isOpen={!!error} 
        message={error} 
        onClose={() =>  dispatch(clearState())}
      />
    </>
  );
}

export default SingleAttachmentUploadForm;

