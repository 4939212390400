import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncStatus, ERROR_MESSAGES } from '../../../shared/constants';
import { ServerError, serverErrorSchema } from '../../../shared/schema';

import { ReentryPortalUser, ReentryPortalUserQueryFilter } from 'unity-types';
import { reentryPortalUserSchema, reentryPortalUserFilterSchema } from 'unity-types/schema';

interface UserState {
  status: AsyncStatus;
  error: string | null;
  allUsers: ReentryPortalUser[] | null;
  myUser: ReentryPortalUser | null;
  getUserStatus: AsyncStatus;
  getUserError: string | null;
  viewUser: ReentryPortalUser | null;
}

const initialState: UserState = {
  status: AsyncStatus.Idle,
  error: null,
  allUsers: null,
  myUser: null,
  getUserStatus: AsyncStatus.Idle,
  getUserError: null,
  viewUser: null,
};

export const getAllUsers = createAsyncThunk<ReentryPortalUser[], { agencyId: string }, { rejectValue: ServerError }>(
  'reentry_portal/users/getAllUsers', 
  async (payload, { rejectWithValue }) => {
    try {
      const filter: ReentryPortalUserQueryFilter = reentryPortalUserFilterSchema.parse(payload);
      const query = new URLSearchParams(filter as any).toString();
      const response = await axios.get(`/api/reentry_portal/users?${query}`);
      const users: ReentryPortalUser[] = response.data.map((user: any) => reentryPortalUserSchema.parse(user));
      return users;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getUser = createAsyncThunk<ReentryPortalUser, { userId: string }, { rejectValue: ServerError }>(
  'reentry_portal/users/getUser', 
  async (payload, { rejectWithValue }) => {
    const { userId } = payload;
    try {
      const response = await axios.get(`/api/reentry_portal/users/${userId}`);
      const user: ReentryPortalUser = reentryPortalUserSchema.parse(response.data);
      return user;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const usersSlice = createSlice({
  name: 'rp_users',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
    setMyUser: (state, action: PayloadAction<{ myUser: ReentryPortalUser }>) => {
      state.myUser = action.payload.myUser;
    },
    clearViewUser: (state) => {
      state.viewUser = initialState.viewUser;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
        state.allUsers = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.error = message;
      })
      .addCase(getUser.pending, (state, action) => {
        state.getUserStatus = AsyncStatus.Loading;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.getUserStatus = AsyncStatus.Succeeded;
        state.viewUser = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.getUserStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getUserError = message;
      })
  }
});

export const { 
  clearState,
  setMyUser,
  clearViewUser
} = usersSlice.actions;

export default usersSlice.reducer;