import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { getSchemaPermissionSets } from '../../../slices/permissions/permissions.slice';
import { font_lg, font_sm_giant, notification_font } from '../../../../shared/styles';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import { PortalName, PermissionSetsExtendedInfo as PermissionSet, } from 'unity-types';
import { SmallSpinner } from '../../../../shared/components/elements/Spinner';

const ViewPermissionsCard = () => {
  const [css, $theme] = useStyletron();
  const dispatch = useDispatch();
  const viewPortal: PortalName | null = useSelector(state => state.admin_portal.ap_permissions.viewPortal);
  const allPermissionSets: { schema: PortalName, data: PermissionSet[] } | null = useSelector(state => state.admin_portal.ap_permissions.allPermissionSets);
  const [accentColor, setAccentColor] = useState<string>();

  useEffect(() => {
    if(viewPortal) {
      switch(viewPortal) {
        case PortalName.Admin_Portal:
          setAccentColor($theme.colors.accent);
          break;
        case PortalName.Reentry_Portal:
          setAccentColor($theme.colors.reentry_accent);
          break;
        case PortalName.Dig_Portal:
          setAccentColor($theme.colors.dig_accent);
          break;
        default:
          setAccentColor($theme.colors.accent);
          break;
      }
    }
  }, [viewPortal, $theme]);

  useEffect(() => {
    if(viewPortal && !allPermissionSets) dispatch(getSchemaPermissionSets({ schema: viewPortal }));
  }, [viewPortal, allPermissionSets, dispatch]);

  return (
    (viewPortal && allPermissionSets) ?
    <Card overrides={{ border: 'none', backgroundColor: 'transparent', flexDirection: 'row', justifyContent: 'flex-start' }}>
      <FlexGrid overrides={{ marginRight: '40px' }}>
        <FlexGridItem>
          <div className={css({ ...notification_font, color: accentColor })}>{viewPortal}</div>
          <div className={css({ ...font_sm_giant, color: accentColor })}>Permissions</div>
        </FlexGridItem>
      </FlexGrid>
      {
        allPermissionSets.data.map((permissionSet: PermissionSet, i: number) => <ViewPermissionSetCard permissionSet={permissionSet} key={i} accentColor={accentColor}/>)
      }
    </Card> : <SmallSpinner/>
  );
}

export default ViewPermissionsCard;

type ViewPermissionSetCardProps = {
  permissionSet: PermissionSet;
  accentColor: string | undefined;
};

const ViewPermissionSetCard = ({permissionSet, accentColor}: ViewPermissionSetCardProps) => {
  return (
    <Card overrides={{ border: 'none', marginRight: '40px' }}>
      <FlexGridItem overrides={{ ...font_lg, color: accentColor, marginBottom: '5px' }}>{permissionSet.permissionSet}</FlexGridItem>
      <FlexGrid overrides={{ flexDirection: 'column', rowGap: 'none' }}>
        {permissionSet.permissions.map((permission: string, i: number) => <FlexGridItem key={i} overrides={{ ...notification_font, color: accentColor }}>{permission}</FlexGridItem>)}
      </FlexGrid>
    </Card>
  );
};
