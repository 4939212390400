import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { FlexGrid } from '../../../../shared/components/layout/FlexGrid';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import SelectSchemaForm from '../../forms/permissions/SelectSchemaForm';
import ViewPermissionsCard from '../../cards/permissions/ViewPermissionsCard';
import { PortalName } from 'unity-types';

const PermissionsHomePage = () => {
  const viewPortal: PortalName | null = useSelector(state => state.admin_portal.ap_permissions.viewPortal);

  return (
    <StyledContainer>
      <FlexGrid overrides={{ width: '100%', justifyContent: 'center' }}>
        { viewPortal === null ? <SelectSchemaForm/> : <ViewPermissionsCard/> }
      </FlexGrid>
    </StyledContainer>
  );

}

export default PermissionsHomePage;
