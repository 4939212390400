import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Theme, ThemeProvider } from 'baseui';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../redux/hooks';
import { AsyncStatus } from '../shared/constants';
import { find } from 'lodash';
import { hasPortalAccess } from './utils';

import { getCurrentAgency } from './slices/agency/agency.slice';
import { getSelectFieldOptions } from './slices/schema/schema.slice';
import { getAllUsers, setMyUser } from './slices/user/user.slice';

import { Outlet } from 'react-router-dom';
import NavBar from './components/navbars/NavBar';

import { 
  type AuthenticatedUser, 
  type Agency, 
  type PgEnum, 
  type ReentryPortalUser 
} from 'unity-types';

const Portal_Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authenticatedUser: AuthenticatedUser | null = useSelector(state => state.shared.auth.authenticatedUser);
  const getSessionStatus: AsyncStatus = useSelector(state => state.shared.auth.getSessionStatus);
  const userAgency: Agency | null = useSelector(state => state.reentry_portal.rp_agencies.userAgency);
  const userAgencyStatus: AsyncStatus = useSelector(state => state.reentry_portal.rp_agencies.userAgencyStatus);
  const selectFields: PgEnum[] | null = useSelector(state => state.reentry_portal.rp_schema.selectFields);
  const schemaStatus: AsyncStatus = useSelector(state => state.reentry_portal.rp_schema.status);
  const usersStatus: AsyncStatus = useSelector(state => state.reentry_portal.rp_users.status);
  const allUsers: ReentryPortalUser[] | null = useSelector(state => state.reentry_portal.rp_users.allUsers);
  const myUser: ReentryPortalUser | null = useSelector(state => state.reentry_portal.rp_users.myUser);
  const theme: Theme = useSelector(state => state.shared.theme.reentry);

  // Redirect user to home page if they are not authorized to access the reentry portal

  useEffect(() => {
    if(getSessionStatus !== AsyncStatus.Succeeded) return;
    if(!hasPortalAccess(authenticatedUser)) navigate('/');
  }, [authenticatedUser, navigate, getSessionStatus]);

  /* ---FETCH INITIAL DATA--- */

  useEffect(() => {
    if(!authenticatedUser || (!authenticatedUser.reentry_portal)) return;
    if(!userAgency && userAgencyStatus === AsyncStatus.Idle) {
      dispatch(getCurrentAgency());
    }
  }, [authenticatedUser, userAgency, userAgencyStatus, dispatch]);

  useEffect(() => {
    if(!authenticatedUser || (!authenticatedUser.reentry_portal)) return;
    if(!selectFields && schemaStatus === AsyncStatus.Idle) {
      dispatch(getSelectFieldOptions());
    }
  }, [authenticatedUser, selectFields, schemaStatus, dispatch]);

  useEffect(() => {
    if(!authenticatedUser || (!authenticatedUser.reentry_portal)) return;
    if(!allUsers && userAgency && usersStatus === AsyncStatus.Idle) {
      dispatch(getAllUsers({ agencyId: userAgency.id }));
    } else {
      if(allUsers && !myUser && authenticatedUser) {
        const user = find(allUsers, (user) => user.id === authenticatedUser.id)!;
        dispatch(setMyUser({ myUser: user }));
      }
    }
  }, [authenticatedUser, userAgency, allUsers, myUser, usersStatus, dispatch]);

  /* ------ */

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Outlet />
    </ThemeProvider>
  );
}

export default Portal_Index;

