import { useEffect, useState } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { useNavigate } from 'react-router-dom';
import { find } from 'lodash';
import { formatDateString, getUserDetailPageUrl } from '../../../utils';
import { font_med, font_lg, font_extra_lg } from '../../../../shared/styles';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import { clearViewPayment } from '../../../slices/payment/payment.slice';
import { clearViewVendor } from '../../../slices/vendor/vendor.slice';
import { type Payment, type ReentryAgencyExtendedInfo, type ReentryPortalUser } from 'unity-types';

const PaymentHeaderCard = () => {
  const [css, $theme] = useStyletron();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const viewPayment: Payment | null = useSelector(state => state.reentry_portal.rp_payments.viewPayment);
  const userAgency: ReentryAgencyExtendedInfo | null = useSelector(state => state.reentry_portal.rp_agencies.userAgency);
  const allUsers: ReentryPortalUser[] | null = useSelector(state => state.reentry_portal.rp_users.allUsers);
  const [createdByUser, setCreatedByUser] = useState<ReentryPortalUser>();
  const [isRefund, setIsRefund] = useState<boolean>();
  const [isRefunded, setIsRefunded] = useState<boolean>();

  useEffect(() => {
    if(viewPayment && !createdByUser) {
      const isRefund = viewPayment.type === 'Refund';
      setIsRefund(isRefund);
      const isRefunded = viewPayment.refundPaymentId !== null;
      setIsRefunded(isRefunded);
      const createdByUser = find(allUsers, (user) => user.id === viewPayment.createdByUserId);
      setCreatedByUser(createdByUser);
    }
  }, [allUsers, viewPayment, createdByUser]);

  return (
    (viewPayment && userAgency && createdByUser) &&
    <Card overrides={{ padding: '10px', width: 'auto' }}>
      <FlexGrid overrides={{ alignItems: 'flex-start', marginTop: '-5px' }}>
      <FlexGridItem overrides={{ ...font_extra_lg, color: isRefund ? $theme.colors.positive : $theme.colors.negative }}>{viewPayment.type}</FlexGridItem>
      { isRefunded && <FlexGridItem overrides={{ ...font_lg, color: $theme.colors.positive }}>Refunded</FlexGridItem> }

      </FlexGrid>
      <FlexGridItem overrides={{ ...font_lg }}>Payment #: {viewPayment.paymentCode}</FlexGridItem>
      <FlexGridItem overrides={{ ...font_med }}>{userAgency.name}</FlexGridItem>
      <FlexGridItem overrides={{ ...font_med }}>{userAgency.community}, AK</FlexGridItem>
      {
        createdByUser && 
        <FlexGrid overrides={{ flexDirection: 'column', rowGap: '0px' }}>
          <FlexGridItem overrides={{ ...font_med, marginTop: '10px', display: 'flex', alignItems: 'center' }}>
            <FlexGridItem>Created By: </FlexGridItem>
            <FlexGridItem overrides={{ marginLeft: '10px' }}>
              <Card
                overrides={{ 
                  padding:'5px', 
                  ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' }, 
                  backgroundColor: $theme.colors.white,
                  color: $theme.colors.accent
                }} 
                onClick={() => {
                  dispatch(clearViewPayment());
                  dispatch(clearViewVendor());
                  navigate(getUserDetailPageUrl(createdByUser.id));
                }}
              >{createdByUser.fullName}
              </Card>
            </FlexGridItem>
          </FlexGridItem>
          <FlexGridItem overrides={{ ...font_med, marginTop: '5px' }}>
            Created Date:  <span className={css({ ...font_med, color: $theme.colors.accent, padding: '0px 5px 0px 5px', borderRadius: '10px' })}>{formatDateString(viewPayment.createdDt)}</span>
          </FlexGridItem>
        </FlexGrid>
      }
    </Card>
  )
}

export default PaymentHeaderCard;
