import { useState, useEffect, Fragment } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { getClientDetailPageUrl, formatCurrencyAmount } from '../../../utils';
import { resetUpdateClientAsyncState, clearViewClient, getAllClients, clearAllClients, updateClient } from '../../../slices/client/client.slice';
import { AsyncStatus } from '../../../../shared/constants';
import { InfoCard as Card } from '../../../../shared/components/elements/Card';
import Select from '../../../../shared/components/elements/Select';
import Input from '../../../../shared/components/elements/Input';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { LargeButton } from '../../../../shared/components/elements/Button';
import { HelpTextModal, NotificationModal } from '../../../../shared/components/elements/Modal';
import { validateAmountField } from './validate';
import { UpdateClientDTO, type ReentryAgencyExtendedInfo, type Client } from 'unity-types';
import { type SelectOption } from '../../../../shared/types';
import { InfoIcon } from '../../../../shared/components/elements/Icon';
import { notification_font, font_sm_giant, font_med_lg } from '../../../../shared/styles';

const AdjustClientObligatedFundsForm = () => {
  const [css, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAgency: ReentryAgencyExtendedInfo | null = useSelector(state => state.reentry_portal.rp_agencies.userAgency);
  const viewClient: Client | null = useSelector(state => state.reentry_portal.rp_clients.viewClient);
  const updateClientStatus: AsyncStatus = useSelector(state => state.reentry_portal.rp_clients.updateClientStatus);
  const updateClientError: string | null = useSelector(state => state.reentry_portal.rp_clients.updateClientError);
  const [action, setAction] = useState<SelectOption[]>([]);
  const [updatedObligatedFunds, setUpdatedObligatedFunds] = useState<number>();
  const [validationError, setValidationError] = useState<string>();
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [helpTextModalIsOpen, setHelpTextModalIsOpen] = useState(false);

  useEffect(() => {
    if (updateClientStatus === AsyncStatus.Succeeded) {
      setSuccessModalIsOpen(true);
    }
  }, [updateClientStatus]);

  const validateAndSubmit = () => {
    if (!updatedObligatedFunds || !viewClient || !userAgency) return;

    // values for comparison
    const additionalAllocation = updatedObligatedFunds - viewClient.obligatedFunds;
    const maximumAdditionalAllocation = userAgency.fundsRemaining;
    const maximumRelease = viewClient.obligatedFunds - viewClient.totalPaid;
    const totalPaid = viewClient.totalPaid;

    // formatted amounts for error message display
    const updatedObligatedFunds_formatted = formatCurrencyAmount(updatedObligatedFunds);
    const totalPaid_formatted = formatCurrencyAmount(viewClient.totalPaid);
    const additionalAllocation_formatted = formatCurrencyAmount(additionalAllocation);
    const maximumAdditionalAllocation_formatted = formatCurrencyAmount(maximumAdditionalAllocation);
    const maximumRelease_formatted = formatCurrencyAmount(maximumRelease);

    // validate funds allocated
    if (additionalAllocation > maximumAdditionalAllocation) {
      return setValidationError(
        `This additional allocation ($${additionalAllocation_formatted}) would result in the client's new budget exceeding the remaining 
        award for your agency. The maximum amount you can allocate is $${maximumAdditionalAllocation_formatted}`);
    }

    // validate funds released
    if (updatedObligatedFunds < totalPaid) {
      return setValidationError(
        `This adjustment would result in the client's new budget ($${updatedObligatedFunds_formatted}) being less than the amount that
        has already been paid ($${totalPaid_formatted}). The maximum amount you can release is: $${maximumRelease_formatted}.`
      );
    }

    const dto: UpdateClientDTO = {
      obligatedFunds: updatedObligatedFunds,
    }

    const payload = {
      id: viewClient.id,
      dto: dto
    }

    dispatch(updateClient(payload));
  }

  return (
    viewClient &&
    <FlexGridItem overrides={{ width: 'auto' }}>
      <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px', height: 'auto', width: '600px' }}>
        <FlexGrid>
          <FlexGridItem overrides={{ textAlign: 'left', alignItems: 'center' }}>
            <div className={css({ ...notification_font, color: $theme.colors.white, textAlign: 'left', alignItems: 'center' })}>adjust {viewClient.fullName}'s</div>
            <div className={css({ ...font_sm_giant, color: $theme.colors.white, textAlign: 'left', alignItems: 'center' })}>Budget</div>
          </FlexGridItem>
          <InfoIcon onClick={() => setHelpTextModalIsOpen(true)} />
        </FlexGrid>
        <FlexGrid overrides={{ justifyContent: 'center' }}>
          <FlexGrid overrides={{ flexDirection: 'column', rowGap: '0px', marginTop: '40px', width: '400px' }}>
            <Select
              value={action}
              label={'Choose an Action'}
              onChange={params => {
                setAction(params.value as SelectOption[]);
                setUpdatedObligatedFunds(undefined);
              }}
              options={[
                { id: 'allocateAdditionalFunds', label: 'Allocate Additional Funds' },
                { id: 'releaseFunds', label: 'Release Funds' },
              ]}
            />
            {
              !action ? <Fragment /> :
                action.length === 0 ? <Fragment /> :
                  action[0].id === 'allocateAdditionalFunds' ? <AllocateAdditionalFundsForm currentObligatedFunds={viewClient.obligatedFunds} setUpdatedObligatedFunds={setUpdatedObligatedFunds} /> :
                    action[0].id === 'releaseFunds' ? <ReleaseFundsForm currentObligatedFunds={viewClient.obligatedFunds} setUpdatedObligatedFunds={setUpdatedObligatedFunds} /> :
                      <Fragment />
            }

            <FlexGridItem overrides={{ marginTop: '20px', marginBottom: '40px' }}>
              <LargeButton
                label={'Submit'}
                onClick={validateAndSubmit}
                disabled={updatedObligatedFunds === undefined}
                isLoading={updateClientStatus === AsyncStatus.Loading}
              />
            </FlexGridItem>
          </FlexGrid>
        </FlexGrid>
      </Card>
      <NotificationModal
        isOpen={!!validationError}
        message={validationError}
        onClose={() => {
          setValidationError(undefined);
          setAction([]);
          setUpdatedObligatedFunds(undefined);
        }}
      />
      <NotificationModal
        isOpen={successModalIsOpen}
        message={`Successfully adjusted ${viewClient.firstName}'s budget.`}
        onClose={() => {
          dispatch(resetUpdateClientAsyncState());
          dispatch(clearAllClients());
          if (userAgency) dispatch(getAllClients({ agencyId: userAgency.id }));
          navigate(getClientDetailPageUrl(viewClient.id));
          dispatch(clearViewClient());
        }}
      />
      <NotificationModal
        isOpen={!!updateClientError}
        message={updateClientError}
        onClose={() => dispatch(resetUpdateClientAsyncState())}
      />
      <HelpTextModal isOpen={helpTextModalIsOpen} setIsOpen={setHelpTextModalIsOpen}>
        <FlexGridItem overrides={{ ...font_med_lg, color: $theme.colors.white }}>Adjusting a client's budget:</FlexGridItem>
        <FlexGridItem overrides={{ ...notification_font, marginTop: '10px', color: $theme.colors.white }}>
          When you allocate additional funds to a client, the "obligated funds" for that client will increase by the specified amount. This means that more funds will be reserved for this client's program participation.
        </FlexGridItem>
        <FlexGridItem overrides={{ ...notification_font, marginTop: '10px', color: $theme.colors.white }}>
          When you release funds from a client, the obligated Funds for that client will decrease by the specified amount. This means that the client will no longer be allocated the released funds, and they will be available for other uses within the program.
        </FlexGridItem>
        <FlexGridItem overrides={{ ...notification_font, marginTop: '10px', color: $theme.colors.white }}>
          Please note that releasing funds does not impact the "total paid" amount for the client. It simply adjusts the amount of funds still available for the client's program participation.
        </FlexGridItem>
        <FlexGridItem overrides={{ ...notification_font, marginTop: '10px', color: $theme.colors.white }}>
          You cannot adjust the budget for a client that has exited the program. Increasing the obligated funds for a client may decrease the number of available slots for new clients. Please ensure that additional funds are allocated judiciously to maintain support for new clients.
        </FlexGridItem>
      </HelpTextModal>
    </FlexGridItem>
  );
}

export default AdjustClientObligatedFundsForm;

type AllocateAdditionalFundsFormProps = {
  currentObligatedFunds: number,
  setUpdatedObligatedFunds: React.Dispatch<React.SetStateAction<number | undefined>>
}

const AllocateAdditionalFundsForm = ({ currentObligatedFunds, setUpdatedObligatedFunds }: AllocateAdditionalFundsFormProps) => {
  const [additionalFunds, setAdditionalFunds] = useState('');
  const [additionalFundsError, setAdditionalFundsError] = useState<string>();
  return (
    <Input
      value={additionalFunds}
      label={'How much do you want to allocate?'}
      onChange={(e) => {
        setUpdatedObligatedFunds(undefined);
        setAdditionalFunds(e.target.value);
      }}
      onBlur={() => {
        setUpdatedObligatedFunds(undefined);
        const [isValid, error] = validateAmountField(additionalFunds);
        if (!isValid) return setAdditionalFundsError(error);
        setAdditionalFundsError(undefined);
        setUpdatedObligatedFunds(currentObligatedFunds + parseFloat(additionalFunds));
      }}
      error={additionalFundsError}
    />
  )
}

type ReleaseFundsFormProps = AllocateAdditionalFundsFormProps

const ReleaseFundsForm = ({ currentObligatedFunds, setUpdatedObligatedFunds }: ReleaseFundsFormProps) => {
  const [releasedFunds, setReleasedFunds] = useState('');
  const [releasedFundsError, setReleasedFundsError] = useState<string>();
  return (
    <Input
      value={releasedFunds}
      label={'How much do you want to release?'}
      onChange={(e) => {
        setUpdatedObligatedFunds(undefined);
        setReleasedFunds(e.target.value);
      }}
      onBlur={() => {
        setUpdatedObligatedFunds(undefined);
        const [isValid, error] = validateAmountField(releasedFunds);
        if (!isValid) return setReleasedFundsError(error);
        setReleasedFundsError(undefined);
        setUpdatedObligatedFunds(currentObligatedFunds - parseFloat(releasedFunds));
      }}
      error={releasedFundsError}
    />
  )
}