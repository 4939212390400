import { combineReducers } from '@reduxjs/toolkit';
import usersReducer, { usersSlice } from './user/user.slice';
import permissionsReducer, { permissionsSlice } from './permissions/permissions.slice';
import reportsReducer, { reportsSlice } from './reports/reports.slice';

export default combineReducers({
  [usersSlice.name]: usersReducer,
  [permissionsSlice.name]: permissionsReducer,
  [reportsSlice.name]: reportsReducer,
});
