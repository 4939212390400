import { useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../../redux/hooks';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { useStyletron } from 'baseui';
import { submitLogout, clearState as clearAuthState } from '../../../shared/slices/auth/auth.slice';
import { clearState as clearRegisterState } from '../../../shared/slices/register/register.slice';
import { clearState as clearAgenciesState, getCurrentAgency } from '../../slices/agency/agency.slice';
import { clearState as clearClientsState, clearNewClient, clearViewClient, resetGetAllClientsAsyncState, getAllClients } from '../../slices/client/client.slice';
import { clearState as clearSchemaState } from '../../slices/schema/schema.slice';
import { clearAllVendorsByUser, clearNewVendor, clearState as clearVendorState, clearViewVendor } from '../../slices/vendor/vendor.slice';
import { clearState as clearPaymentState, resetNewPayment, clearViewPayment, clearAllPaymentsByClient, clearAllPaymentsByVendor, clearAllPaymentsByUser } from '../../slices/payment/payment.slice';
import { clearState as clearUserState, clearViewUser } from '../../slices/user/user.slice';
import { clearState as clearDocumentState } from '../../slices/attachment/attachment.slice';
import { navlink, link, clear_default_button, font_sm, special_font } from '../../../shared/styles';
import { AsyncStatus } from '../../../shared/constants';
import Button from '../../../shared/components/elements/Button';
import { MediumLogo } from '../../../shared/components/elements/Logo';
import { MyUserHeaderCard } from '../cards/users/MyUserHeaderCard';
import { ContactsModal, FaqsModal } from '../../../shared/components/elements/Modal';
import { FlexGrid, FlexGridItem } from '../../../shared/components/layout/FlexGrid';
import { SmallSpinner } from '../../../shared/components/elements/Spinner';
import Card from '../../../shared/components/elements/Card';
import { type DigPortalUser, type DigAgencyExtendedInfo } from 'unity-types';
import { type LinkData } from '../../../shared/types';
import { setProgramPortalTheme } from '../../../shared/slices/theme/theme.slice';
import { barbiePinkTheme } from '../../../shared/styles/theme';

const NavBar = () => {
  const [css, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitLogoutStatus: AsyncStatus = useSelector(state => state.shared.auth.submitLogoutStatus);
  const myUser: DigPortalUser | null = useSelector(state => state.dig_portal.dp_users.myUser);
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.dp_agencies.userAgency);
  const [contactsModaIsOpen, setContactsModalIsOpen] = useState(false);
  const [faqsModalIsOpen, setFaqsModalIsOpen] = useState(false);

  useEffect(() => {
    if(submitLogoutStatus === AsyncStatus.Succeeded) {
      dispatch(clearAuthState());
      dispatch(clearRegisterState());
      dispatch(clearAgenciesState());
      dispatch(clearClientsState());
      dispatch(clearSchemaState());
      dispatch(clearVendorState());
      dispatch(clearPaymentState());
      dispatch(clearUserState());
      navigate('/login');
    }
  }, [submitLogoutStatus, navigate, dispatch]);

  const primaryLinks: LinkData[] = [
    { 
      to: '', 
      label: 'Home',
      onClick: () => {
        dispatch(clearViewClient())
        dispatch(clearViewPayment())
        dispatch(clearViewVendor())
        dispatch(clearViewUser())
        dispatch(resetNewPayment())
        dispatch(clearAllPaymentsByClient())
        dispatch(clearAllPaymentsByVendor())
        dispatch(clearAllPaymentsByUser())
        dispatch(clearAllVendorsByUser())
        if(userAgency) dispatch(getAllClients({ agencyId: userAgency.id }))
        dispatch(getCurrentAgency())
      } 
    },
    { 
      to: 'clients', 
      label: 'Add New Client',
      onClick: () => {
        dispatch(clearNewClient())
        dispatch(resetGetAllClientsAsyncState())
        dispatch(clearViewClient())
      }
    },
    { 
      to: 'payments', 
      label: 'Input Payment',
      onClick: () => {
        dispatch(resetNewPayment())
        dispatch(clearDocumentState())
      }
    },
    { 
      to: 'vendors', 
      label: 'Create Vendor',
      onClick: () => {
        dispatch(clearNewVendor())
        dispatch(clearDocumentState())
      }
    },
  ];

  return (
    <>
      <FlexGrid overrides={{ flexDirection: 'column', marginBottom: '37.5px' }}>
        <FlexGridItem>
          <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white }} >
            <div className={css({...special_font, color: $theme.colors.white, textAlign: 'center' })}>Discharge Incentive Grant (DIG) Program Portal</div>
          </Card>
        </FlexGridItem>
        <FlexGridItem overrides={{ padding: '10px', marginBottom: '-67.5px' }}>
          <FlexGrid overrides={{ justifyContent: 'space-between'}}>
            <FlexGridItem>
              <FlexGrid overrides={{ flexDirection: 'column' }}>
                <FlexGridItem overrides={{ zIndex: 2 }}>
                  <MediumLogo overrides={{ paddingTop: '0px', marginBottom: '-13px' }}/>
                </FlexGridItem>
              </FlexGrid>
            </FlexGridItem>
            <FlexGridItem>
              <FlexGrid overrides={{ flexDirection: 'column', zIndex: 2 }}>
                <FlexGridItem overrides={{ alignItems: 'center' }}>
                  <Button 
                    label='Log Out'
                    onClick={() => dispatch(submitLogout())} 
                    isLoading={submitLogoutStatus === AsyncStatus.Loading} 
                    overrides={{ height: '60px', width: '110px', float: 'right', marginLeft: '5px' }}
                  />
                  <Button 
                    label='Unity Home'
                    onClick={() => navigate('/')}
                    overrides={{ height: '60px', width: '140px', float: 'right', marginLeft: '5px', backgroundColor: $theme.colors.grey }}
                  />
                  {/* <Button 
                    label='Find a Record'
                    onClick={() => {
                      dispatch(clearViewClient());
                      dispatch(clearViewPayment());
                      dispatch(clearViewVendor());
                      navigate('/dig-portal/search');
                    }} 
                    overrides={{ ...font_sm, height: '60px', width: '175px', marginLeft: '5px', float: 'right', backgroundColor: $theme.colors.light_grey, color: $theme.colors.black }}
                  /> */}
                  {/* <Button 
                    label='Barbie Mode'
                    onClick={() => {
                      dispatch(setProgramPortalTheme({ program: 'dig', theme: barbiePinkTheme }));
                    }} 
                    overrides={{ ...font_sm, height: '60px', width: '175px', float: 'right', marginLeft: '20px', backgroundColor: $theme.colors.light_grey, color: $theme.colors.black }}
                  /> */}
                  <button 
                    onClick={() => setContactsModalIsOpen(true)} 
                    className={css({ 
                      ...clear_default_button, 
                      ...link,
                      borderColor: $theme.colors.accent,
                      ':hover': {
                        color: $theme.colors.accent,
                      } 
                    })}
                  >Contacts</button>
                  <button 
                    onClick={() => setFaqsModalIsOpen(true)} 
                    className={css({ 
                      ...clear_default_button, 
                      ...link,
                      borderColor: $theme.colors.accent,
                      ':hover': {
                        color: $theme.colors.accent,
                      } 
                    })}
                  >FAQs</button>
                  <Link
                    to={'https://www.ahfc.us/'}
                    className={css({ 
                      ...link, 
                      paddingLeft: '0px',
                      borderColor: $theme.colors.accent,
                      ':hover': {
                        color: $theme.colors.accent,
                      }  
                    })}
                  >AHFC</Link>
                </FlexGridItem>
                <FlexGridItem>
                  {(myUser && userAgency) ? <MyUserHeaderCard myUser={myUser} userAgency={userAgency}/> : <div className={css({ marginRight: '10px', float: 'right' })}><SmallSpinner /></div>}
                </FlexGridItem>
              </FlexGrid>
            </FlexGridItem>
          </FlexGrid>
        </FlexGridItem>
        <FlexGridItem>
          <nav className={css({ display: 'flex', justifyContent: 'center' })}>
            {
              primaryLinks.map((link, i) => {
                const navlinkStyle = { ...navlink, ':hover': { color: $theme.colors.accent }};
                const navlinkActiveStyle = { ...navlinkStyle, color: $theme.colors.accent, backgroundColor: $theme.colors.light_grey };
                return (
                <NavLink 
                  key={i} 
                  end={link.to === ''}
                  to={link.to}
                  onClick={link.onClick}
                  className={({ isActive }) => {
                    const isHome = link.to === '';
                    const linkStyle = isActive && isHome ? navlinkActiveStyle : navlinkStyle;
                    return css(linkStyle);
                  }}
                >{link.label}</NavLink>);
              })
            }
          </nav>
        </FlexGridItem>
      </FlexGrid>
      <ContactsModal isOpen={contactsModaIsOpen} setIsOpen={setContactsModalIsOpen} />
      <FaqsModal isOpen={faqsModalIsOpen} setIsOpen={setFaqsModalIsOpen} />
    </>
  );
}

export default NavBar;
