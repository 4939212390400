import { createSlice } from '@reduxjs/toolkit';
import { AsyncStatus } from '../../../shared/constants';
import { idOnlySchema, IdOnlyType } from '../../../shared/schema';
import { createAsyncThunkByType } from '../../../shared/thunk';

import { ReentryAgencyExtendedInfo, UpdateUserAgencyDTO } from 'unity-types';
import { reentryAgencyExtendedInfoSchema, updateUserAgencyDTOSchema } from 'unity-types/schema';

interface AgencyState {
  userAgencyStatus: AsyncStatus;
  userAgencyError: string | null;
  userAgency: ReentryAgencyExtendedInfo | null;
  updateAgencyStatus: AsyncStatus;
  updateAgencyError: string | null;
}

const initialState: AgencyState = {
  userAgencyStatus: AsyncStatus.Idle,
  userAgencyError: null,
  userAgency: null,
  updateAgencyStatus: AsyncStatus.Idle,
  updateAgencyError: null,
};

export const getCurrentAgency = createAsyncThunkByType<ReentryAgencyExtendedInfo, undefined>(
  "GET", 'reentry_portal/agencies/current', 
  reentryAgencyExtendedInfoSchema, undefined);

export const updateCurrentAgency = createAsyncThunkByType<IdOnlyType, UpdateUserAgencyDTO>(
  "PUT", 'reentry_portal/agencies/current', 
  idOnlySchema, false,
  updateUserAgencyDTOSchema
);

export const agenciesSlice = createSlice({
  name: 'rp_agencies',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
    resetUpdateAgencyAsyncState: (state) => {
      state.updateAgencyStatus = initialState.updateAgencyStatus;
      state.updateAgencyError = initialState.updateAgencyError;
    },
    clearUserAgency: (state) => {
      state.userAgency = initialState.userAgency;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCurrentAgency.pending, (state) => {
        state.userAgencyStatus = AsyncStatus.Loading;
      })
      .addCase(getCurrentAgency.fulfilled, (state, action) => {
        state.userAgencyStatus = AsyncStatus.Succeeded;
        state.userAgency = action.payload;
      })
      .addCase(getCurrentAgency.rejected, (state, action) => {
        state.userAgencyStatus = AsyncStatus.Failed;
        state.userAgencyError = action.error.message!;
      })
      .addCase(updateCurrentAgency.pending, (state) => {
        state.updateAgencyStatus = AsyncStatus.Loading;
      })
      .addCase(updateCurrentAgency.fulfilled, (state) => {
        state.updateAgencyStatus = AsyncStatus.Succeeded;
      })
      .addCase(updateCurrentAgency.rejected, (state, action) => {
        state.updateAgencyStatus = AsyncStatus.Failed;
        state.updateAgencyError = action.error.message!;
      })
  }
});

export const { 
  clearState,
   resetUpdateAgencyAsyncState, 
   clearUserAgency 
} = agenciesSlice.actions;

export default agenciesSlice.reducer;
