import { useAppSelector as useSelector } from '../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { font_sm_giant, font_sm, font_med, font_lg } from '../../../shared/styles';
import { calculateRequiredFundsForNextSlot, formatCurrencyAmount } from '../../utils';
import Card from '../../../shared/components/elements/Card';
import { FlexGrid, FlexGridItem } from '../../../shared/components/layout/FlexGrid';
import { type DigAgencyExtendedInfo } from 'unity-types';

export const SlotsDashboard = () => {
  const [, $theme] = useStyletron();
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.dp_agencies.userAgency);

  return (
    userAgency &&
    <Card overrides={{ padding: '10px', backgroundColor: $theme.colors.white, borderColor: $theme.colors.accent, width: '100%', justifyContent: 'space-between' }}>
      <FlexGrid overrides={{ flexDirection: 'column', justifyContent: 'flex-end', rowGap: '0px' }}>
        <FlexGridItem overrides={{ ...font_sm_giant, lineHeight: '70px', textAlign: 'center', color: $theme.colors.accent }}>{userAgency.remainingSlots}</FlexGridItem>
        <FlexGridItem overrides={{ ...font_med, textAlign: 'center', color: $theme.colors.accent }}>Slot(s) Available</FlexGridItem>
        <FlexGridItem overrides={{ ...font_sm, textAlign: 'center', color: $theme.colors.black }}>at the current standard allocation of</FlexGridItem>
        <FlexGridItem overrides={{ ...font_lg, textAlign: 'center', color: $theme.colors.accent }}>${formatCurrencyAmount(userAgency.standardAllocation)}</FlexGridItem>
        
      </FlexGrid>
      {
        userAgency.remainingSlots === 0 &&
        <FlexGrid overrides={{ ...font_med, color: $theme.colors.black, marginTop: '30px', flexDirection: 'column', alignItems: 'center' }}>
            <FlexGridItem overrides={{ ...font_lg, backgroundColor: $theme.colors.positive, color: $theme.colors.white, padding: '0px 5px', borderRadius: '5px', width: 'auto', textAlign: 'center' }}>
              ${calculateRequiredFundsForNextSlot(userAgency)}
            </FlexGridItem>
            <FlexGridItem overrides={{ ...font_sm, textAlign: 'center' }}>Required for New Slot</FlexGridItem>
        </FlexGrid>
      }
    </Card>
  )
}

export default SlotsDashboard;