import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { updatePayment, resetUpdatePaymentAsyncState, clearViewPayment } from '../../../slices/payment/payment.slice';
import { clearState as clearDocumentState } from '../../../slices/attachment/attachment.slice';
import { useNavigate } from 'react-router-dom';
import { getPaymentDetailPageUrl } from '../../../utils';
import { AsyncStatus } from '../../../../shared/constants';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { InfoCard as Card } from '../../../../shared/components/elements/Card';
import AttachmentUploadForm from '../AttachmentUploadForm';
import Button from '../../../../shared/components/elements/Button';
import { HelpTextModal, NotificationModal } from '../../../../shared/components/elements/Modal';
import { type Payment, type CreateAttachmentResponseDTO, type UpdatePaymentDTO } from 'unity-types';
import { InfoIcon } from '../../../../shared/components/elements/Icon';
import { font_med_lg, font_sm_giant, notification_font } from '../../../../shared/styles';

const UploadReceiptForm = () => {
  const [css, $theme] = useStyletron();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const viewPayment: Payment | null = useSelector(state => state.reentry_portal.rp_payments.viewPayment);
  const createAttachmentResponse: CreateAttachmentResponseDTO | null = useSelector(state => state.reentry_portal.rp_attachments.createAttachmentResponse);
  const updatePaymentStatus: AsyncStatus = useSelector(state => state.reentry_portal.rp_payments.updatePaymentStatus);
  const updatePaymentError: string | null = useSelector(state => state.reentry_portal.rp_payments.updatePaymentError);
  const [helpTextModalIsOpen, setHelpTextModalIsOpen] = useState(false);

  useEffect(() => {
    // redirect to home if user landed on this page without a payment
    if (!viewPayment) {
      navigate('/');
    }
  }, [viewPayment, navigate]);

  const submit = () => {
    if (!createAttachmentResponse || !viewPayment) return;

    const dto: UpdatePaymentDTO = {
      receiptAttachmentId: createAttachmentResponse!.id
    }

    const payload = {
      id: viewPayment.id,
      dto: dto
    };

    dispatch(updatePayment(payload));
  }

  return (
    viewPayment &&
    <FlexGridItem overrides={{ width: 'auto' }}>
      <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px', width: 'auto' }}>
        <FlexGrid>
          <FlexGridItem overrides={{ textAlign: 'left', alignItems: 'center' }}>
            <div className={css({ ...notification_font, color: $theme.colors.white, textAlign: 'left', alignItems: 'center' })}>Upload</div>
            <div className={css({ ...font_sm_giant, color: $theme.colors.white, textAlign: 'left', alignItems: 'center' })}>Receipt</div>
          </FlexGridItem>
          <InfoIcon onClick={() => setHelpTextModalIsOpen(true)} />
        </FlexGrid>
        <FlexGridItem>
          <AttachmentUploadForm title={''} type={'Receipt'} />
        </FlexGridItem>
        {
          createAttachmentResponse &&
          <FlexGridItem overrides={{ marginTop: '10px' }}>
            <Button label={'Attach to payment'} onClick={submit} isLoading={updatePaymentStatus === AsyncStatus.Loading} />
          </FlexGridItem>
        }
      </Card>
      <NotificationModal
        isOpen={updatePaymentStatus === AsyncStatus.Succeeded}
        message={`Successfully uploaded receipt.`}
        onClose={() => {
          dispatch(resetUpdatePaymentAsyncState());
          navigate(getPaymentDetailPageUrl(viewPayment.id));
          dispatch(clearDocumentState());
          dispatch(clearViewPayment());
        }}
      />
      <NotificationModal
        isOpen={!!updatePaymentError}
        message={updatePaymentError}
        onClose={() => dispatch(resetUpdatePaymentAsyncState())}
      />
      <HelpTextModal isOpen={helpTextModalIsOpen} setIsOpen={setHelpTextModalIsOpen}>
        <FlexGridItem>
          <FlexGridItem overrides={{ ...font_med_lg, color: $theme.colors.white }}>Uploading a Receipt:</FlexGridItem>
          <FlexGridItem overrides={{ ...notification_font, marginTop: '10px', color: $theme.colors.white }}>
          When the upload is complete, a button will appear labeled "Attach to Payment".
          Click that to attach the receipt to the payment record. Uploading a new receipt will overrite any existing ones on this payment.
          </FlexGridItem>
        </FlexGridItem>
      </HelpTextModal>
    </FlexGridItem>
  );
}


export default UploadReceiptForm;
