import { useStyletron } from 'baseui';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { formatDateString } from '../../../utils';
import { font_sm, SIZE, font_extra_sm } from '../../../../shared/styles';
import Card from '../../../../shared/components/elements/Card';
import Title from '../../../../shared/components/elements/Title';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Button from '../../../../shared/components/elements/Button';
import { type Client } from 'unity-types';
import React from 'react';

const ClientInfoCard = () => {
  const [css, $theme] = useStyletron();
  const navigate = useNavigate();
  const viewClient: Client | null = useSelector(state => state.reentry_portal.rp_clients.viewClient);

  const Field = ({ children }: { children: React.ReactNode }) => {
    return <span className={css({ color: $theme.colors.accent })}>{children}</span>
  }

  return (
    <Card overrides={{ padding: '10px' }}>
      {
        viewClient &&
        <FlexGrid overrides={{ ...font_sm, flexDirection: 'column' }}>
          <Card overrides={{ padding: '5px', backgroundColor: $theme.colors.accent, color: $theme.colors.white, width: 'auto' }}>
            <Title title={'Personal Information'} color={$theme.colors.white} size={SIZE.SMALL} />
            <Card overrides={{ padding: '5px', width: 'auto', color: $theme.colors.black }}>
              <FlexGridItem>First Name: <Field>{viewClient.firstName}</Field></FlexGridItem>
              <FlexGridItem>Last Name: <Field>{viewClient.lastName}</Field></FlexGridItem>
              <FlexGridItem>Date of Birth: <Field>{formatDateString(viewClient.dateOfBirth)}</Field></FlexGridItem>
              <FlexGridItem>Email Address: <Field>{viewClient.emailAddress}</Field></FlexGridItem>
              <FlexGridItem>Phone Number: <Field>{viewClient.phoneNumber}</Field></FlexGridItem>
            </Card>
          </Card>
          <Card overrides={{ padding: '5px', backgroundColor: $theme.colors.accent, color: $theme.colors.white, width: 'auto' }}>
            <Title title={'Address Information'} color={$theme.colors.white} size={SIZE.SMALL} />
            <Card overrides={{ padding: '5px', width: 'auto', color: $theme.colors.black }}>
              <FlexGridItem>Street: <Field>{viewClient.street}</Field></FlexGridItem>
              <FlexGridItem>City: <Field>{viewClient.city}</Field></FlexGridItem>
              <FlexGridItem>State: <Field>{viewClient.state}</Field></FlexGridItem>
              <FlexGridItem>Zip Code: <Field>{viewClient.zipCode}</Field></FlexGridItem>
            </Card>
          </Card>
          <Card overrides={{ padding: '5px', backgroundColor: $theme.colors.accent, color: $theme.colors.white, width: 'auto' }}>
            <Title title={'Demographics'} color={$theme.colors.white} size={SIZE.SMALL} />
            <Card overrides={{ padding: '5px', width: 'auto', color: $theme.colors.black }}>
              <FlexGridItem>Race: <Field>{viewClient.race}</Field></FlexGridItem>
              <FlexGridItem>Ethnicity: <Field>{viewClient.ethnicity}</Field></FlexGridItem>
              <FlexGridItem>Gender: <Field>{viewClient.gender}</Field></FlexGridItem>
              <FlexGridItem>Household Members: <Field>{viewClient.householdMembers}</Field></FlexGridItem>
              <FlexGridItem>Household Minors: <Field>{viewClient.householdMinors}</Field></FlexGridItem>
            </Card>
          </Card>
          <FlexGridItem overrides={{ textAlign: 'right', ...font_extra_sm, width: 'auto' }}>
            <Button label={'Update Client Details'} onClick={() => navigate('/reentry-portal/clients/update-client')} />
          </FlexGridItem>
        </FlexGrid>
      }
    </Card>
  )
}

export default ClientInfoCard;