import { useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { getClientById, resetGetClientAsyncState } from '../../../slices/client/client.slice';
import { BlankContainer } from '../../../../shared/components/layout/StyledContainer';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import ExtraLargeSpinner from '../../../../shared/components/elements/Spinner';
import ClientHeaderCard from '../../cards/clients/ClientHeaderCard';
import ClientInfoCard from '../../cards/clients/ClientInfoCard';
import ProgramStatusCard from '../../cards/clients/ProgramStatusCard';
import FinancialStatusCard from '../../cards/clients/FinancialStatusCard';
import FinancialBreakdownCard from '../../cards/clients/FinancialBreakdownCard';
import { ClientPaymentList } from '../../lists/payments/ClientPaymentList';
import { type Client } from 'unity-types';
import { NotificationModal } from '../../../../shared/components/elements/Modal';

const ClientDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { recordId } = useParams();
  const viewClient: Client | null = useSelector(state => state.reentry_portal.rp_clients.viewClient);
  const getClientError = useSelector(state => state.reentry_portal.rp_clients.getClientError);

  useEffect(() => {
    dispatch(getClientById({ id: recordId! }));
  }, [recordId, dispatch]);

  return (
    <BlankContainer>
      {
        viewClient ?
        <FlexGrid overrides={{ justifyContent: 'space-between', margin: '10px' }}>
          <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '25%' }}>
            <ClientHeaderCard />
            <ProgramStatusCard />
          </FlexGridItem>
          <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '30%' }}>
            <FinancialStatusCard />
            <FinancialBreakdownCard />
          </FlexGridItem>
          <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '25%' }}>
            <ClientPaymentList />
          </FlexGridItem>
          <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '20%' }}>
            <ClientInfoCard />
          </FlexGridItem>
        </FlexGrid> : <FlexGrid overrides={{ margin: '10px' }}><ExtraLargeSpinner/></FlexGrid>
      }
      {
        getClientError && 
        <NotificationModal 
          isOpen={getClientError != null} 
          message={getClientError} 
          onClose={() => {
            dispatch(resetGetClientAsyncState());
            navigate('/reentry-portal');
          }}
        />
      }
    </BlankContainer>
  );
}

export default ClientDetailPage;
