import { createSlice, createAsyncThunk, PayloadAction, Draft } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncStatus, ERROR_MESSAGES } from '../../../shared/constants';
import { ServerError, serverErrorSchema } from '../../../shared/schema';

import { Vendor, VendorQueryFilter, CreateVendorDTO } from 'unity-types';
import { vendorSchema, vendorQueryFilterSchema, createVendorDTOSchema } from 'unity-types/schema'
import { type NewVendor, type SetFieldNewVendorPayloadAction } from './types';
import { createAsyncThunkByType } from '../../../shared/thunk';

interface VendorState {
  status: AsyncStatus;
  error: string | null;
  allVendors: Vendor[] | null;
  matches: Vendor[] | null;
  viewVendor: Vendor | null;
  newVendor: NewVendor | null;
  newVendorRecordId: string | null;
  getAllVendorsByUserStatus: AsyncStatus;
  getAllVendorsByUserError: string | null;
  allVendorsByUser: Vendor[] | null;
}

const initialState: VendorState = {
  status: AsyncStatus.Idle,
  error: null,
  allVendors: null,
  matches: null,
  viewVendor: null,
  newVendor: null,
  newVendorRecordId: null,
  getAllVendorsByUserStatus: AsyncStatus.Idle,
  getAllVendorsByUserError: null,
  allVendorsByUser: null
};

export const createNewVendor = createAsyncThunkByType<Vendor, CreateVendorDTO>(
  "POST", "reentry_portal/vendors",
  vendorSchema, false,
  createVendorDTOSchema
);

export const getAllVendors = createAsyncThunk<Vendor[], VendorQueryFilter, { rejectValue: ServerError }>(
  'reentry_portal/vendors/getAllVendors', 
  async (payload, { rejectWithValue }) => {
    try {
      const filter: VendorQueryFilter = vendorQueryFilterSchema.parse(payload);
      const query = new URLSearchParams(filter as any).toString();
      const response = await axios.get(`/api/reentry_portal/vendors?${query}`);
      const vendors: Vendor[] = response.data.map((vendor: any) => vendorSchema.parse(vendor));
      return vendors;
    }
    catch (e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getVendor = createAsyncThunk<Vendor, VendorQueryFilter, { rejectValue: ServerError }>(
  'reentry_portal/vendors/getVendor', 
  async (payload, { rejectWithValue }) => {
    try {
      const filter: VendorQueryFilter = vendorQueryFilterSchema.parse(payload);
      const query = new URLSearchParams(filter as any).toString();
      const response = await axios.get(`/api/reentry_portal/vendors?${query}`);
      const vendors: Vendor[] = response.data.map((vendor: any) => vendorSchema.parse(vendor));
      return vendors[0];
    }
    catch (e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getVendorById = createAsyncThunk<Vendor, { id: string }, { rejectValue: ServerError }>(
  'reentry_portal/vendors/getVendorById', 
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/reentry_portal/vendors/${id}`);
      const vendor: Vendor = vendorSchema.parse(response.data);
      return vendor;
    }
    catch (e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getAllVendorsByUser = createAsyncThunk<Vendor[], VendorQueryFilter, { rejectValue: ServerError }>(
  'reentry_portal/vendors/getAllVendorsByUser', 
  async (payload, { rejectWithValue }) => {
    try {
      const filter: VendorQueryFilter = vendorQueryFilterSchema.parse(payload);
      const query = new URLSearchParams(filter as any).toString();
      const response = await axios.get(`/api/reentry_portal/vendors?${query}`);
      const vendors: Vendor[] = response.data.map((vendor: any) => vendorSchema.parse(vendor));
      return vendors;
    }
    catch (e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const vendorsSlice = createSlice({
  name: 'rp_vendors',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
    resetCreateNewVendorState: (state) => {
      state.status = initialState.status;
      state.error = initialState.error;
      state.matches = initialState.matches;
      state.newVendor = initialState.newVendor;
      state.newVendorRecordId = initialState.newVendorRecordId;
    },
    resetGetVendorState: (state) => {
      state.status = initialState.status;
      state.error = initialState.error;
      state.viewVendor = initialState.viewVendor;
    },
    buildNewVendor: (state) => {
      const newVendor: NewVendor = {
        name: '',
        category: []
      };
      state.newVendor = newVendor;
    },
    clearNewVendor: (state) => {
      state.newVendor = initialState.newVendor;
      state.newVendorRecordId = initialState.newVendorRecordId;
    },
    setFieldOnNewVendor: <K extends keyof NewVendor>(state: Draft<VendorState>, action: PayloadAction<SetFieldNewVendorPayloadAction<K>>) => {
      const { key, value } = action.payload;
      state.newVendor![key] = value;
    },
    clearViewVendor: (state) => {
      state.viewVendor = initialState.viewVendor;
    },
    clearAllVendorsByUser: (state) => {
      state.allVendorsByUser = initialState.allVendorsByUser;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createNewVendor.pending, (state) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(createNewVendor.fulfilled, (state, action) => {
        state.newVendorRecordId = action.payload.id;
        state.viewVendor = action.payload;
        state.status = AsyncStatus.Succeeded;
      })
      .addCase(createNewVendor.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        state.error = "A vendor by the name already exists.";
      })
      .addCase(getAllVendors.pending, (state) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(getAllVendors.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
        state.allVendors = action.payload;
      })
      .addCase(getAllVendors.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        state.error = action.error.message!;
      })
      .addCase(getVendor.pending, (state) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(getVendor.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
        state.viewVendor = action.payload;
      })
      .addCase(getVendor.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.error = message;
      })
      .addCase(getVendorById.pending, (state) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(getVendorById.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
        state.viewVendor = action.payload;
      })
      .addCase(getVendorById.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.error = message;
      })
      .addCase(getAllVendorsByUser.pending, (state) => {
        state.getAllVendorsByUserStatus = AsyncStatus.Loading;
      })
      .addCase(getAllVendorsByUser.fulfilled, (state, action) => {
        state.getAllVendorsByUserStatus = AsyncStatus.Succeeded;
        state.allVendorsByUser = action.payload;
      })
      .addCase(getAllVendorsByUser.rejected, (state, action) => {
        state.getAllVendorsByUserStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getAllVendorsByUserError = message;
      })
  }
});

export const { 
  clearState,
  resetCreateNewVendorState,
  resetGetVendorState,
  buildNewVendor,
  clearNewVendor,
  setFieldOnNewVendor,
  clearViewVendor,
  clearAllVendorsByUser
} = vendorsSlice.actions;

export default vendorsSlice.reducer;
