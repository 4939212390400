import { createSlice, createAsyncThunk, PayloadAction, Draft } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncStatus, ERROR_MESSAGES } from '../../../shared/constants';
import { ServerError, serverErrorSchema } from '../../../shared/schema';

import {
  Payment,
  CreatePaymentDTO,
  CreateRefundDTO,
  PaymentQueryFilter,
  UpdatePaymentDTO,
} from 'unity-types';

import { 
  paymentSchema,
  createPaymentDTOSchema,
  createRefundDTOSchema,
  paymentQueryFilterSchema 
} from 'unity-types/schema';

import {
  NewPayment,
  NewRefund,
  SetFieldNewRefundPayloadAction,
  SetFieldNewPaymentPayloadAction
} from './types';

interface PaymentState {
  status: AsyncStatus;
  error: string | null;
  viewPayment: Payment | null;
  newPayment: NewPayment | null;
  newRefund: NewRefund | null;
  newPaymentRecordId: string | null;
  newRefundRecordId: string | null;
  getSiblingPaymentStatus: AsyncStatus;
  getSiblingPaymentError: string | null;
  viewSiblingPayment: Payment | null;
  getAllPaymentsByClientStatus: AsyncStatus;
  getAllPaymentsByClientError: string | null;
  allPaymentsByClient: Payment[] | null;
  getAllPaymentsByVendorStatus: AsyncStatus;
  getAllPaymentsByVendorError: string | null;
  allPaymentsByVendor: Payment[] | null;
  getAllPaymentsByUserStatus: AsyncStatus;
  getAllPaymentsByUserError: string | null;
  allPaymentsByUser: Payment[] | null;
  getAllPaymentsStatus: AsyncStatus;
  getAllPaymentsError: string | null;
  allPayments: Payment[] | null;
  updatePaymentStatus: AsyncStatus;
  updatePaymentError: string | null;
}

const initialState: PaymentState = {
  status: AsyncStatus.Idle,
  error: null,
  viewPayment: null,
  newPayment: null,
  newRefund: null,
  newPaymentRecordId: null,
  newRefundRecordId: null,
  getSiblingPaymentStatus: AsyncStatus.Idle,
  getSiblingPaymentError: null,
  viewSiblingPayment: null,
  getAllPaymentsByClientStatus: AsyncStatus.Idle,
  getAllPaymentsByClientError: null,
  allPaymentsByClient : null,
  getAllPaymentsByVendorStatus: AsyncStatus.Idle,
  getAllPaymentsByVendorError: null,
  allPaymentsByVendor : null,
  getAllPaymentsByUserStatus: AsyncStatus.Idle,
  getAllPaymentsByUserError: null,
  allPaymentsByUser : null,
  getAllPaymentsStatus: AsyncStatus.Idle,
  getAllPaymentsError: null,
  allPayments: null,
  updatePaymentStatus: AsyncStatus.Idle,
  updatePaymentError: null,
};

export const createNewPayment = createAsyncThunk<Payment, CreatePaymentDTO, { rejectValue: ServerError }>(
  'dig_portal/payments/createNewPayment', 
  async (payload, { rejectWithValue }) => {
    try {
      const dto: CreatePaymentDTO = createPaymentDTOSchema.parse(payload);
      const response = await axios.post('/api/dig_portal/payments', dto);
      const payment: Payment = paymentSchema.parse(response.data);
      return payment;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const createNewRefund = createAsyncThunk<{ id: string }, CreateRefundDTO, { rejectValue: ServerError }>(
  'dig_portal/payments/createNewRefund', 
  async (payload, { rejectWithValue }) => {
    try {
      const dto: CreateRefundDTO = createRefundDTOSchema.parse(payload);
      const paymentId = dto.originalPaymentId;
      const response = await axios.post(`/api/dig_portal/payments/${paymentId}/refunds`, dto);
      return response.data;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getPayment = createAsyncThunk<Payment, PaymentQueryFilter, { rejectValue: ServerError }>(
  'dig_portal/payments/getPayment', 
  async (payload, { rejectWithValue }) => {
    try {
      const filter: PaymentQueryFilter = paymentQueryFilterSchema.parse(payload);
      const query = new URLSearchParams(filter as any).toString();
      const response = await axios.get(`/api/dig_portal/payments?${query}`);
      const payment: Payment = paymentSchema.parse(response.data[0]);
      return payment;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getSiblingPayment = createAsyncThunk<Payment, PaymentQueryFilter, { rejectValue: ServerError }>(
  'dig_portal/payments/getSiblingPayment', 
  async (payload, { rejectWithValue }) => {
    try {
      const filter: PaymentQueryFilter = paymentQueryFilterSchema.parse(payload);
      const query = new URLSearchParams(filter as any).toString();
      const response = await axios.get(`/api/dig_portal/payments?${query}`);
      const payment: Payment = paymentSchema.parse(response.data[0]);
      return payment;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getAllPaymentsByClient = createAsyncThunk<Payment[], { clientId: string, agencyId: string }, { rejectValue: ServerError }>(
  'dig_portal/payments/getAllPaymentsByClient', 
  async (payload, { rejectWithValue }) => {
    try {
      const filter: PaymentQueryFilter = paymentQueryFilterSchema.parse(payload);
      const query = new URLSearchParams(filter as any).toString();
      const response = await axios.get(`/api/dig_portal/payments?${query}`);
      const payments: Payment[] = response.data.map((payment: any) => paymentSchema.parse(payment));
      return payments;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getAllPaymentsByVendor = createAsyncThunk<Payment[], { payeeVendorId: string, agencyId: string }, { rejectValue: ServerError }>(
  'dig_portal/payments/getAllPaymentsByVendor', 
  async (payload, { rejectWithValue }) => {
    try {
      const filter: PaymentQueryFilter = paymentQueryFilterSchema.parse(payload);
      const query = new URLSearchParams(filter as any).toString();
      const response = await axios.get(`/api/dig_portal/payments?${query}`);
      const payments: Payment[] = response.data.map((payment: any) => paymentSchema.parse(payment));
      return payments;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getAllPaymentsByUser = createAsyncThunk<Payment[], { createdByUserId: string, agencyId: string }, { rejectValue: ServerError }>(
  'dig_portal/payments/getAllPaymentsByUser', 
  async (payload, { rejectWithValue }) => {
    try {
      const filter: PaymentQueryFilter = paymentQueryFilterSchema.parse(payload);
      const query = new URLSearchParams(filter as any).toString();
      const response = await axios.get(`/api/dig_portal/payments?${query}`);
      const payments: Payment[] = response.data.map((payment: any) => paymentSchema.parse(payment));
      return payments;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getAllPayments = createAsyncThunk<Payment[], { agencyId: string }, { rejectValue: ServerError }>(
  'dig_portal/payments/getAllPayments', 
  async (payload, { rejectWithValue }) => {
    try {
      const filter: PaymentQueryFilter = paymentQueryFilterSchema.parse(payload);
      const query = new URLSearchParams(filter as any).toString();
      const response = await axios.get(`/api/dig_portal/payments?${query}`);
      const payments: Payment[] = response.data.map((payment: any) => paymentSchema.parse(payment));
      return payments;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const updatePayment = createAsyncThunk<{ id: string }, { id: string, dto: UpdatePaymentDTO }, { rejectValue: ServerError }>(
  'dig_portal/payments/updatePayment', 
  async (payload, { rejectWithValue }) => {
    const { id, dto } = payload;
    try {
      const response = await axios.put(`/api/dig_portal/payments/${id}`, dto);
      return response.data;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const paymentsSlice = createSlice({
  name: 'dp_payments',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
    resetAsyncPaymentState: (state) => {
      state.status = initialState.status;
      state.error = initialState.error;
    },
    clearViewPayment: (state) => {
      state.viewPayment = initialState.viewPayment;
    },
    clearViewSiblingPayment: (state) => {
      state.viewSiblingPayment = initialState.viewSiblingPayment;
    },
    clearAllPaymentsByClient: (state) => {
      state.allPaymentsByClient = initialState.allPaymentsByClient;
    },
    clearAllPaymentsByVendor: (state) => {
      state.allPaymentsByVendor = initialState.allPaymentsByVendor;
    },
    clearAllPaymentsByUser: (state) => {
      state.allPaymentsByUser = initialState.allPaymentsByUser;
    },
    clearAllPayments: (state) => {
      state.allPayments = initialState.allPayments;
    },
    buildNewPayment: (state) => {
      const newPayment: NewPayment = {
        amount: '',
        categorySelectOption: [],
        clientSelectOption: [],
        payeeSelectOption: [],
        notes: '',
      };
      state.newPayment = newPayment;
    },
    resetNewPayment: (state) => {
      state.newPayment = initialState.newPayment;
      state.newPaymentRecordId = initialState.newPaymentRecordId;
    },
    resetNewRefund: (state) => {
      state.newRefund = initialState.newRefund;
      state.viewPayment = initialState.viewPayment;
      state.newRefundRecordId = initialState.newRefundRecordId;
    },
    setFieldOnNewPayment: <K extends keyof NewPayment>(state: Draft<PaymentState>, action: PayloadAction<SetFieldNewPaymentPayloadAction<K>>) => {
      const { key, value } = action.payload;
      state.newPayment![key] = value;
    },
    setFieldOnNewRefund: <K extends keyof NewRefund>(state: Draft<PaymentState>, action: PayloadAction<SetFieldNewRefundPayloadAction<K>>) => {
      const { key, value } = action.payload;
      state.newRefund![key] = value;
    },
    stagePaymentForRefund: (state) => {
      if(!state.viewPayment) return;
      const newRefund: NewRefund = {
        originalPaymentId: state.viewPayment.id,
        notes: '',
        clientName: state.viewPayment.clientName!,
        payeeName: state.viewPayment.payeeName,
        category: state.viewPayment.category,
        amount: state.viewPayment.amount * -1,
      };
      state.newRefund = newRefund;
    },
    resetUpdatePaymentAsyncState: (state) => {
      state.updatePaymentStatus = initialState.updatePaymentStatus;
      state.updatePaymentError = initialState.updatePaymentError;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createNewPayment.pending, (state) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(createNewPayment.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
        state.newPaymentRecordId = action.payload.id;
        state.viewPayment = action.payload;
      })
      .addCase(createNewPayment.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.error = message;
      })
      .addCase(createNewRefund.pending, (state) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(createNewRefund.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
        state.newRefundRecordId = action.payload.id;
      })
      .addCase(createNewRefund.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.error = message;
      })
      .addCase(getPayment.pending, (state) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(getPayment.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
        state.viewPayment = action.payload;
      })
      .addCase(getPayment.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.error = message;
      })
      .addCase(getSiblingPayment.pending, (state) => {
        state.getSiblingPaymentStatus = AsyncStatus.Loading;
      })
      .addCase(getSiblingPayment.fulfilled, (state, action) => {
        state.getSiblingPaymentStatus = AsyncStatus.Succeeded;
        state.viewSiblingPayment = action.payload;
      })
      .addCase(getSiblingPayment.rejected, (state, action) => {
        state.getSiblingPaymentStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getSiblingPaymentError = message;
      })
      .addCase(getAllPaymentsByClient.pending, (state) => {
        state.getAllPaymentsByClientStatus = AsyncStatus.Loading;
      })
      .addCase(getAllPaymentsByClient.fulfilled, (state, action) => {
        state.getAllPaymentsByClientStatus = AsyncStatus.Succeeded;
        state.allPaymentsByClient = action.payload;
      })
      .addCase(getAllPaymentsByClient.rejected, (state, action) => {
        state.getAllPaymentsByClientStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getAllPaymentsByClientError = message;
      })
      .addCase(getAllPaymentsByVendor.pending, (state) => {
        state.getAllPaymentsByVendorStatus = AsyncStatus.Loading;
      })
      .addCase(getAllPaymentsByVendor.fulfilled, (state, action) => {
        state.getAllPaymentsByVendorStatus = AsyncStatus.Succeeded;
        state.allPaymentsByVendor = action.payload;
      })
      .addCase(getAllPaymentsByVendor.rejected, (state, action) => {
        state.getAllPaymentsByVendorStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getAllPaymentsByVendorError = message;
      })
      .addCase(getAllPaymentsByUser.pending, (state) => {
        state.getAllPaymentsByUserStatus = AsyncStatus.Loading;
      })
      .addCase(getAllPaymentsByUser.fulfilled, (state, action) => {
        state.getAllPaymentsByUserStatus = AsyncStatus.Succeeded;
        state.allPaymentsByUser = action.payload;
      })
      .addCase(getAllPaymentsByUser.rejected, (state, action) => {
        state.getAllPaymentsByUserStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getAllPaymentsByUserError = message;
      })
      .addCase(getAllPayments.pending, (state) => {
        state.getAllPaymentsStatus = AsyncStatus.Loading;
      })
      .addCase(getAllPayments.fulfilled, (state, action) => {
        state.getAllPaymentsStatus = AsyncStatus.Succeeded;
        state.allPayments = action.payload;
      })
      .addCase(getAllPayments.rejected, (state, action) => {
        state.getAllPaymentsStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getAllPaymentsError = message;
      })
      .addCase(updatePayment.pending, (state) => {
        state.updatePaymentStatus = AsyncStatus.Loading;
      })
      .addCase(updatePayment.fulfilled, (state) => {
        state.updatePaymentStatus = AsyncStatus.Succeeded;
      })
      .addCase(updatePayment.rejected, (state, action) => {
        state.updatePaymentStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.updatePaymentError = message;
      })
  }
});

export const { 
  clearState,
  resetAsyncPaymentState,
  clearViewPayment,
  clearViewSiblingPayment,
  clearAllPaymentsByClient,
  clearAllPaymentsByVendor,
  clearAllPaymentsByUser,
  clearAllPayments,
  buildNewPayment,
  resetNewPayment,
  resetNewRefund,
  setFieldOnNewPayment,
  setFieldOnNewRefund,
  stagePaymentForRefund,
  resetUpdatePaymentAsyncState
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
