import { useState, useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { buildNewVendor } from '../../../slices/vendor/vendor.slice';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import ProgressSteps from '../../../../shared/components/elements/ProgressSteps';
import VendorDetailsForm from '../../forms/vendors/VendorDetailsForm';
import ReviewForm from '../../forms/vendors/ReviewForm';
import DocumentUploadForm from '../../forms/AttachmentUploadForm';
import { type NewVendor } from '../../../slices/vendor/types';
import { type ProgressStep } from '../../../../shared/types';

const NewVendorPage = () => {
  const dispatch = useDispatch();
  const newVendor: NewVendor | null = useSelector(state => state.reentry_portal.rp_vendors.newVendor);
  const[activeStep, setActiveStep] = useState<number>(0);
  const [isValidStep, setIsValidStep] = useState<boolean>(true);

  useEffect(() => {
    if(newVendor === null) {
      dispatch(buildNewVendor());
    }
  }, [newVendor, dispatch])

  const steps: ProgressStep[] = [{
    title: 'Details',
    component: <VendorDetailsForm setIsValidStep={setIsValidStep} />
  }, {
    title: 'Upload W-9 Form',
    component: <DocumentUploadForm title={'Please upload a W-9'}  type={'W-9'} />
  }, {
    title: 'Review',
    component: <ReviewForm setActiveStep={setActiveStep} />
  }];

  const setPreviousStep = () => {
    if(activeStep <= 0) return;
    setActiveStep(activeStep - 1);
  }

  const setNextStep = () => {
    if(activeStep >= steps.length - 1) return;
    setActiveStep(activeStep + 1);
  }

  return (
    <StyledContainer>
      <FlexGrid overrides={{ justifyContent: 'center', marginTop: '20px' }}>
        <FlexGridItem overrides={{ width: '280px' }}>
        <ProgressSteps steps={steps} activeStep={activeStep} setPreviousStep={setPreviousStep} setNextStep={setNextStep} isValidStep={isValidStep}/>
        </FlexGridItem>
        <FlexGridItem overrides={{ width: '30%' }}>
          { newVendor && steps[activeStep].component }
        </FlexGridItem>
      </FlexGrid>
    </StyledContainer>
  );
}

export default NewVendorPage;
