import { useState, useEffect } from 'react';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { formatDateString } from '../../../utils';
import { font_lg, font_med, font_extra_lg } from '../../../../shared/styles';
import { FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import { type UnityUser } from 'unity-types';

const UserHeaderCard = () => {
  const [css, $theme] = useStyletron();
  const viewUser: UnityUser | null = useSelector(state => state.admin_portal.ap_users.viewUnityUser);
  const [daysOnApplication, setDaysOnApplication] = useState<number>();

  useEffect(() => {
    if(viewUser) {
      const userCreatedDate = new Date(viewUser.createdDt).getTime();
      const currentDate = new Date().getTime();
      const timeDiff = currentDate - userCreatedDate;
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      setDaysOnApplication(days);
    }
  }, [viewUser]);

  return (
    (viewUser) &&
    <Card overrides={{ padding: '10px', width: 'auto', borderColor: $theme.colors.accent }}>
      <FlexGridItem overrides={{ ...font_extra_lg, color: $theme.colors.black }}>{viewUser.agencyName}</FlexGridItem>
      <FlexGridItem overrides={{ ...font_extra_lg, color: $theme.colors.accent }}>{viewUser.fullName}</FlexGridItem>
      <FlexGridItem overrides={{ ...font_lg }}>{viewUser.username}</FlexGridItem>
      <FlexGridItem overrides={{ ...font_med }}>
        Created Date: <span className={css({ color: $theme.colors.accent })}>{formatDateString(viewUser.createdDt)}</span>
      </FlexGridItem>
      <FlexGridItem overrides={{ ...font_med }}>
        Account Age: <span className={css({ color: $theme.colors.accent })}>{daysOnApplication} day[s]</span>
      </FlexGridItem>
    </Card>
  )
}

export default UserHeaderCard;
