import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { useStyletron } from "baseui";
import { clearViewUser } from "../../../slices/user/user.slice";
import { clearAllVendorsByUser } from "../../../slices/vendor/vendor.slice";
import { getVendorDetailPageUrl } from "../../../utils";
import { font_med, font_lg, font_sm } from "../../../../shared/styles";
import { AsyncStatus } from '../../../../shared/constants';
import Card from "../../../../shared/components/elements/Card";
import { FlexGrid, FlexGridItem } from "../../../../shared/components/layout/FlexGrid";
import ExtraLargeSpinner from '../../../../shared/components/elements/Spinner';
import { type ReentryPortalUser, type Vendor } from "unity-types";

export const UserVendorList = () => {
  const [, $theme] = useStyletron();
  const viewUser: ReentryPortalUser | null = useSelector(state => state.reentry_portal.rp_users.viewUser);
  const allVendorsByUser: Vendor[] | null = useSelector(state => state.reentry_portal.rp_vendors.allVendorsByUser);
  const getAllVendorsByUserStatus: AsyncStatus = useSelector(state => state.reentry_portal.rp_vendors.getAllVendorsByUserStatus);

  return (
    <Card overrides={{ padding: '10px 7.5px', backgroundColor: $theme.colors.accent, borderColor: $theme.colors.accent }}>
      <FlexGrid overrides={{ flexDirection: 'column'}}>
        <FlexGridItem overrides={{ ...font_med, color: $theme.colors.white, textAlign: 'center', margin: '-10px' }}>All vendors created by</FlexGridItem>
        <FlexGrid overrides={{ justifyContent: 'center' }}>
          {
            viewUser && <FlexGridItem overrides={{ ...font_lg, backgroundColor: $theme.colors.white, color: $theme.colors.accent, textAlign: 'center', borderRadius: '5px', padding: '0px 5px' }}>{viewUser.fullName}</FlexGridItem>
          }
        </FlexGrid>
        <Card 
          overrides={{ 
            backgroundColor: $theme.colors.white, 
            width: 'auto', 
            borderColor: $theme.colors.white, 
            height: `${window.innerHeight - 390}px`, 
            overflow: 'auto',
            padding: '10px 5px 10px 5px',
            scrollbarColor: `${$theme.colors.accent} ${$theme.colors.white}`,
            scrollbarWidth: 'none'
          }} 
        >
          <FlexGrid overrides={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            {
              getAllVendorsByUserStatus === AsyncStatus.Failed ?
              <></> : !allVendorsByUser ?
              <ExtraLargeSpinner /> : 
              allVendorsByUser.map((vendor, i) => <UserVendorListItem vendor={vendor} key={i} />)
            }
          </FlexGrid>
        </Card>
      </FlexGrid>
    </Card>
  )
}

export default UserVendorList;

export type UserVendorListItemProps = {
  vendor: Vendor;
}

const UserVendorListItem = ({ vendor }: UserVendorListItemProps) => {
  const [, $theme] = useStyletron();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Card 
      overrides={{ 
        padding:'5px', 
        ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' }, 
        backgroundColor: $theme.colors.white,
        width: '100%'
      }} 
      onClick={() => {
        dispatch(clearViewUser());
        dispatch(clearAllVendorsByUser());
        navigate(getVendorDetailPageUrl(vendor.id));
      }}
    >
      <FlexGrid overrides={{ ...font_sm, justifyContent: 'flex-start', alignItems: 'center' }}>
        <FlexGridItem>{vendor.name}</FlexGridItem>
        <FlexGridItem overrides={{ ...font_sm, color: $theme.colors.accent }}>{vendor.name}</FlexGridItem>
      </FlexGrid>
    </Card>
  )
}