import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector } from '../../../../../../redux/hooks';
import { font_med } from '../../../../../shared/styles';
import Card from '../../../../../shared/components/elements/Card';
import ProgressGraph, { type ProgressGraphItem } from '../../../../../shared/components/elements/ProgressGraph';
import { formatCurrencyAmount } from '../../../../utils';
import { FlexGrid, FlexGridItem } from '../../../../../shared/components/layout/FlexGrid';
import { type ReentryAgencyExtendedInfo, type Client } from 'unity-types';

const ObligatedFundsUsed = () => {
  const [, $theme] = useStyletron();
  const userAgency: ReentryAgencyExtendedInfo | null = useSelector(state => state.reentry_portal.rp_agencies.userAgency);
  const allClients: Client[] | null = useSelector(state => state.reentry_portal.rp_clients.allClients);
  const [data, setData] = useState<ProgressGraphItem[]>();
  
  useEffect(() => {
    if(userAgency && allClients) {
      const totalPaidByAllClients = userAgency.totalPaidFunds;
      const totalObligatedFunds = userAgency.totalObligatedFunds;
      const totalObligatedFundsOnHold = totalObligatedFunds - totalPaidByAllClients;
      const ratio = totalPaidByAllClients/totalObligatedFunds;
      const percentTotalPaidByAllClients = Math.floor(ratio * 100);
      const percentObligatedFundsOnHold = 100 - percentTotalPaidByAllClients;
      
      const data: ProgressGraphItem[] = [
        { id: 'totalPaidByAllClients', label: 'Total Paid', percent: percentTotalPaidByAllClients, color: $theme.colors.negative, amount: `$${formatCurrencyAmount(totalPaidByAllClients)}` },
        { id: 'totalObligatedFundsOnHold', label: 'Obligated Funds on Hold', percent: percentObligatedFundsOnHold, color: $theme.colors.positive, amount: `$${formatCurrencyAmount(totalObligatedFundsOnHold)}` },
      ]
      setData(data);
    }
  }, [userAgency, allClients, $theme.colors.negative, $theme.colors.positive]);

  return (
    userAgency &&
    <Card overrides={{ padding: '10px 10px 20px 10px', justifyContent: 'center' }}>
      { data && <ProgressGraph data={data} font={font_med} opacity={1} labelSpacing={'space-between'} /> }
      <FlexGrid overrides={{ justifyContent: 'center' }}>
        <FlexGridItem overrides={{ ...font_med, backgroundColor: $theme.colors.white, color: $theme.colors.black, padding: '0px 5px', borderRadius: '5px' }}>% Paid of Total Obligated (${formatCurrencyAmount(userAgency.totalObligatedFunds)})</FlexGridItem>
      </FlexGrid>
    </Card>
  )
}

export default ObligatedFundsUsed;
