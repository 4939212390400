import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncStatus, ERROR_MESSAGES } from '../../../shared/constants';
import { ServerError, serverErrorSchema } from '../../../shared/schema';

import { PgEnum } from 'unity-types';
import { pgEnumSchema } from 'unity-types/schema';

interface SchemaState {
  status: AsyncStatus;
  error: string | null;
  selectFields: PgEnum[] | null;
}

const initialState: SchemaState = {
  status: AsyncStatus.Idle,
  error: null,
  selectFields: null,
};

export const getSelectFieldOptions = createAsyncThunk<PgEnum[], undefined, { rejectValue: ServerError }>(
  'dig_portal/schema/getSelectFieldOptions', 
  async (payload, { rejectWithValue }) => {
    try {
      const filter = { schema: 'dig_portal' };
      const query = new URLSearchParams(filter as any).toString();
      const response = await axios.get(`/api/shared/schema/enums?${query}`);

      const enums: PgEnum[] = response.data.map((pgEnum: any) => pgEnumSchema.parse(pgEnum));
      return enums;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const schemaSlice = createSlice({
  name: 'dp_schema',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getSelectFieldOptions.pending, (state, action) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(getSelectFieldOptions.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
        state.selectFields = action.payload;
      })
      .addCase(getSelectFieldOptions.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.error = message;
      })
  }
});

export const { clearState } = schemaSlice.actions;

export default schemaSlice.reducer;
