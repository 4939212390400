import { useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useParams } from 'react-router-dom';
import { getUnityUser } from '../../../slices/user/user.slice';
import StandAlonePageContainer from '../../../../shared/components/layout/StandAlonePageContainer';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { BlankContainer } from '../../../../shared/components/layout/StyledContainer';
import UserHeaderCard from '../../cards/users/UserHeaderCard';
import UserRolesCard from '../../cards/users/UserRolesCard';
import ViewPortalUserCard from '../../cards/users/ViewPortalUserCard';
import ExtraLargeSpinner from '../../../../shared/components/elements/Spinner';
import { type UnityUser } from 'unity-types';

const UserDetailPage = () => {
  const { recordId } = useParams();
  const dispatch = useDispatch();
  const viewUser: UnityUser | null = useSelector(state => state.admin_portal.ap_users.viewUnityUser);

  useEffect(() => {
    if(recordId) dispatch(getUnityUser({ userId: recordId }));
  }, [recordId, dispatch]);

  return (
    <BlankContainer>
      <StandAlonePageContainer>
        {
          viewUser ?
          <FlexGrid overrides={{ justifyContent: 'flex-start' }}>
            <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '1500px' }}>
              <UserHeaderCard />
              <UserRolesCard user={viewUser} />
            </FlexGridItem>
            <FlexGrid>
              <ViewPortalUserCard />
            </FlexGrid>
          </FlexGrid> : <FlexGrid overrides={{ margin: '10px' }}><ExtraLargeSpinner/></FlexGrid>
        }
      </StandAlonePageContainer>
    </BlankContainer>
  );
}

export default UserDetailPage;

