import { useState, useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { getSchemaPermissionSets } from '../../../slices/permissions/permissions.slice';
import { updateUserPermissionSet, resetUpdateUserPermissionSetAsyncState, getPortalUser, clearViewPortalUserState } from '../../../slices/user/user.slice';
import { AsyncStatus } from '../../../../shared/constants';
import { FlexGrid } from '../../../../shared/components/layout/FlexGrid';
import Button from '../../../../shared/components/elements/Button';
import Select from '../../../../shared/components/elements/Select';
import { NotificationModal } from '../../../../shared/components/elements/Modal';
import { SelectOption } from '../../../../shared/types';
import { PortalUser } from '../../../slices/user/types';
import { UpdateUserPermissionsDTO, PortalName, PermissionSetsExtendedInfo as PermissionSet } from 'unity-types';

const ChangePermissionsForm = ({ setIsOpen }: { setIsOpen: (isOpen: boolean) => void }) => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  const viewPortalUser: { portal: PortalName, user: PortalUser } | null = useSelector(state => state.admin_portal.ap_users.viewPortalUser);
  const allPermissionSets: { schema: PortalName, data: PermissionSet[] } | null = useSelector(state => state.admin_portal.ap_permissions.allPermissionSets);
  const getAllPermissionSetsStatus: AsyncStatus = useSelector(state => state.admin_portal.ap_permissions.getAllPermissionSetsStatus);
  const [permissionSet, setPermissionSet] = useState<SelectOption[]>([]);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const updateUserPermissionSetStatus: AsyncStatus = useSelector(state => state.admin_portal.ap_users.updateUserPermissionSetStatus);
  const updateUserPermissionSetError: string | null = useSelector(state => state.admin_portal.ap_users.updateUserPermissionSetError);
  const [validationError, setValidationError] = useState<string>();
  const [successModalIsOpen, setSuccessModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (updateUserPermissionSetStatus === AsyncStatus.Succeeded) setSuccessModalIsOpen(true);
  }, [updateUserPermissionSetStatus]);

  useEffect(() => {
    if(viewPortalUser) dispatch(getSchemaPermissionSets({ schema: viewPortalUser.portal }));
  }, [viewPortalUser, dispatch]);

  useEffect(() => {
    if(allPermissionSets) {
      const options: SelectOption[] = allPermissionSets.data.map(item => ({ id: item.permissionSet, label: item.permissionSet }));
      setOptions(options);
    }
  }, [allPermissionSets]);

  const validateAndSubmit = () => {
    if (!viewPortalUser) return;
  
    if (viewPortalUser.user.permissionSet === permissionSet[0].id) {
      setValidationError(`${viewPortalUser.user.firstName} already has "${permissionSet[0].id}" permissions.`);
      return;
    }

    const dto: UpdateUserPermissionsDTO = {
      permissionSet: permissionSet[0].id,
    }
    const payload = {
      userId: viewPortalUser.user.id,
      roleName: viewPortalUser.portal,
      dto: dto,
    };

    dispatch(updateUserPermissionSet(payload));
  }

  return (
    viewPortalUser &&
    <FlexGrid overrides={{ width: '400px'}}>
      <FlexGrid overrides={{ flexDirection: 'column' }}>
        <Select
          value={permissionSet}
          label={'Choose a Permission Set'}
          onChange={params => setPermissionSet(params.value as SelectOption[])}
          options={options}
          isLoading={getAllPermissionSetsStatus === AsyncStatus.Loading}
        />
        <Button 
          label={'Assign Permissions'} 
          onClick={() => validateAndSubmit()} 
          isLoading={updateUserPermissionSetStatus === AsyncStatus.Loading} 
          disabled={validationError !== undefined || permissionSet.length === 0}
          overrides={{ 
            backgroundColor: viewPortalUser.portal === PortalName.Reentry_Portal ? $theme.colors.reentry_accent : viewPortalUser.portal === PortalName.Dig_Portal ? $theme.colors.dig_accent : $theme.colors.accent,
            ':hover': { 
              color: viewPortalUser.portal === PortalName.Reentry_Portal ? $theme.colors.reentry_accent : viewPortalUser.portal === PortalName.Dig_Portal ? $theme.colors.dig_accent : $theme.colors.accent,
              backgroundColor: $theme.colors.white,
            }
          }}
        />
      </FlexGrid>
      <NotificationModal 
        isOpen={!!validationError} 
        message={validationError} 
        onClose={() => setValidationError(undefined)}
      />
      <NotificationModal 
        isOpen={successModalIsOpen} 
        message={`Successfully updated ${viewPortalUser?.user.fullName}'s permissions to "${permissionSet[0]?.id}".`} 
        onClose={() => {
          dispatch(getPortalUser({ portal: viewPortalUser.portal, userId: viewPortalUser.user.id }));
          setSuccessModalIsOpen(false);
          setIsOpen(false);
          dispatch(resetUpdateUserPermissionSetAsyncState());
          dispatch(clearViewPortalUserState());
        }}
      />
      <NotificationModal 
        isOpen={!!updateUserPermissionSetError} 
        message={updateUserPermissionSetError} 
        onClose={() => dispatch(resetUpdateUserPermissionSetAsyncState())}
      />
    </FlexGrid>
  );
}

export default ChangePermissionsForm;