import { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { getClient, resetGetClientAsyncState } from '../../../slices/client/client.slice';
import { AsyncStatus } from '../../../../shared/constants';
import Card from '../../../../shared/components/elements/Card';
import Input from '../../../../shared/components/elements/Input';
import Button from '../../../../shared/components/elements/Button';
import Title from '../../../../shared/components/elements/Title';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { NotificationModal } from '../../../../shared/components/elements/Modal';
import { type DigAgencyExtendedInfo, type Client } from 'unity-types';

export const SearchClientForm = ({ title }: { title: string }) => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.dp_agencies.userAgency);
  const getClientStatus: AsyncStatus = useSelector(state => state.dig_portal.dp_clients.getClientStatus);
  const getClientError: string | null = useSelector(state => state.dig_portal.dp_clients.getClientError);
  const viewClient: Client | null = useSelector(state => state.dig_portal.dp_clients.viewClient);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [validationError, setValidationError] = useState<string>();

  useEffect(() => {
    if(viewClient || getClientError) {
      setConfirmationCode('');
    }
  }, [viewClient, getClientError]);

  const validateAndSubmit = () => {
    if(!userAgency) return;
    if(confirmationCode === '') {
      return setValidationError('Provide a Confirmation Code first.');
    }
    dispatch(getClient({ confirmationCode, agency: userAgency.id }));
  }

  return (
    <FlexGrid overrides={{ justifyContent: 'center', marginTop: '20px' }}>
      <FlexGridItem overrides={{ width: '420px'}}>
        <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px' }} >
          <Title title={title}/>
          <Input
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
            label={'Enter a Confirmation Code:'}
            caption={'You can only view clients that are assigned to your agency'}
          />
          <Button
            onClick={() => validateAndSubmit()}
            label={'Search'}
            isLoading={getClientStatus === AsyncStatus.Loading}
            disabled={!!getClientError}
          />
        </Card>
        <NotificationModal 
          isOpen={!!getClientError} 
          message={getClientError} 
          onClose={() => dispatch(resetGetClientAsyncState())}
        />
        <NotificationModal 
          isOpen={!!validationError} 
          message={validationError} 
          onClose={() => setValidationError('')}
        />
      </FlexGridItem>
    </FlexGrid>
  );
}

export default SearchClientForm;