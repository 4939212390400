import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { BlankContainer } from '../../../../shared/components/layout/StyledContainer';
import StandAlonePageContainer from '../../../../shared/components/layout/StandAlonePageContainer';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import ExtraLargeSpinner from '../../../../shared/components/elements/Spinner';
import AgencyHeaderCard from '../../cards/agencies/AgencyHeaderCard';
import StandardAllocationCard from '../../cards/agencies/StandardAllocationCard';
import FinancialProgressCard from '../../cards/agencies/FinancialProgressCard';
import { type DigAgencyExtendedInfo } from 'unity-types';

const AgencyDetailPage = () => {
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.dp_agencies.userAgency);

  return (
    <BlankContainer>
      <StandAlonePageContainer>
        {
          userAgency ?
          <FlexGrid overrides={{ width: '100%' }}>
            <FlexGrid overrides={{ flexDirection: 'column', width: '520px', justifyContent: 'flex-start', alignItems: 'flex-center', rowGap: '10px' }}>
              <AgencyHeaderCard />
              <FlexGrid>
                <StandardAllocationCard />
              </FlexGrid>
            </FlexGrid>
            <FlexGridItem overrides={{ width: '100%' }}>
              <FinancialProgressCard />
            </FlexGridItem>
          </FlexGrid> : <ExtraLargeSpinner/>
        }
      </StandAlonePageContainer>
    </BlankContainer>
  );
}

export default AgencyDetailPage;
