import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncStatus, ERROR_MESSAGES } from '../../../shared/constants';
import { ServerError, serverErrorSchema } from '../../../shared/schema';

import { PortalName, PermissionSetsExtendedInfo as PermissionSet } from 'unity-types';
import { permissionSetsExtendedInfoSchema as permissionSetSchema } from 'unity-types/schema'

interface PermissionsState {
  viewPortal: PortalName | null;
  getAllPermissionSetsStatus: AsyncStatus;
  getAllPermissionSetsError: string | null;
  allPermissionSets: { schema: PortalName, data: PermissionSet[] } | null;
}

const initialState: PermissionsState = {
  viewPortal: null,
  getAllPermissionSetsStatus: AsyncStatus.Idle,
  getAllPermissionSetsError: null,
  allPermissionSets: null,
};

export const getSchemaPermissionSets = createAsyncThunk<{ schema: PortalName, data: PermissionSet[] }, { schema: PortalName }, { rejectValue: ServerError }>(
  'admin_portal/permissions/getAllPermissionSets', 
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/${payload.schema}/permission_sets/`);
      const permissionSets: PermissionSet[] = response.data.map((permissionSet: PermissionSet) => permissionSetSchema.parse(permissionSet));
      return { schema: payload.schema, data: permissionSets };
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const permissionsSlice = createSlice({
  name: 'ap_permissions',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
    setViewPortal: (state, action: PayloadAction<PortalName>) => {
      state.viewPortal = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getSchemaPermissionSets.pending, (state) => {
        state.getAllPermissionSetsStatus = AsyncStatus.Loading;
      })
      .addCase(getSchemaPermissionSets.fulfilled, (state, action) => {
        state.getAllPermissionSetsStatus = AsyncStatus.Succeeded;
        state.allPermissionSets = action.payload;
      })
      .addCase(getSchemaPermissionSets.rejected, (state, action) => {
        state.getAllPermissionSetsStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getAllPermissionSetsError = message;
      })
  }
});

export const { 
  clearState,
  setViewPortal,
} = permissionsSlice.actions;

export default permissionsSlice.reducer;