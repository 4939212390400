import { useEffect, useState } from 'react';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom'
import { useStyletron } from 'baseui';
import { font_lg, font_med, font_extra_sm } from '../../../../shared/styles';
import { formatCurrencyAmount } from '../../../utils';
import { FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import Button from '../../../../shared/components/elements/Button';
import ProgressGraph, { type ProgressGraphItem } from '../../../../shared/components/elements/ProgressGraph';
import { type Client } from 'unity-types';

const FinancialStatusCard = () => {
  const [css, $theme] = useStyletron();
  const navigate = useNavigate();
  const viewClient: Client | null = useSelector(state => state.reentry_portal.rp_clients.viewClient);
  const [data, setData] = useState<ProgressGraphItem[]>();
  
  useEffect(() => {
    if (!viewClient) return;
    const obligatedFunds = viewClient.obligatedFunds;
    const totalPaid = viewClient.totalPaid;
    const remainingFunds = obligatedFunds - totalPaid;
    const percentTotalPaid = Math.floor( (totalPaid/obligatedFunds) * 100);
    const percentFundsRemaining = 100 - percentTotalPaid;
    const data: ProgressGraphItem[] = [
      { id: 'totalPaid', label: 'Total Paid', percent: percentTotalPaid, color: $theme.colors.negative, amount: `$${formatCurrencyAmount(totalPaid)}` },
      { id: 'fundsRemaining', label: 'Funds Remaining', percent: percentFundsRemaining, color: $theme.colors.positive, amount: `$${formatCurrencyAmount(remainingFunds)}` },
    ]
    setData(data);
  }, [viewClient, $theme.colors.negative, $theme.colors.positive]);

  return (
    viewClient &&
    <Card overrides={{ padding: '10px', justifyContent: 'center', ...font_lg }}>
      <FlexGridItem overrides={{ marginBottom: '10px', textAlign: 'center', backgroundColor: $theme.colors.accent, color: $theme.colors.white, borderRadius: '5px' }}>Budget Progress Overview</FlexGridItem>
      <FlexGridItem overrides={{ textAlign: 'left', marginBottom: '10px', ...font_med }}>
        Obligated Funds: <span className={css({ ...font_lg, color: $theme.colors.white, backgroundColor: $theme.colors.accent, padding: '0px 5px', borderRadius: '5px' })}>${formatCurrencyAmount(viewClient.obligatedFunds)}</span>
      </FlexGridItem>
      {
        viewClient.totalPaid === 0 ?
        <FlexGridItem overrides={{ ...font_med }}>No payments made</FlexGridItem> :
        data && <ProgressGraph data={data} font={font_med} opacity={.6} labelSpacing={'space-between'} />
      }
      <FlexGridItem overrides={{ textAlign: 'right', ...font_extra_sm, width: 'auto' }}>
        <Button label={'Adjust Client Budget'} onClick={() => navigate('/reentry-portal/clients/adjust-budget')} />
      </FlexGridItem>
    </Card>
  )
}

export default FinancialStatusCard;
