import { useEffect } from 'react';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from './redux/hooks';
import { useNavigate, useRoutes } from 'react-router-dom';
import { AsyncStatus } from './modules/shared/constants';
import { getSession } from './modules/shared/slices/auth/auth.slice';

import ReentryPortalRoutes from './modules/reentry_portal/routes';
import DigPortalRoutes from './modules/dig_portal/routes';
import AdminPortalRoutes from './modules/admin_portal/routes';

import NotFoundPage from './modules/shared/components/pages/NotFoundPage';
import HomePage from './modules/shared/components/pages/HomePage';
import LoginPage from './modules/shared/components/pages/LoginPage';
import RegisterPage from './modules/shared/components/pages/RegisterPage';
import ResetPasswordPage from './modules/shared/components/pages/ResetPasswordPage';

import { type AuthenticatedUser } from 'unity-types';
import { Theme, ThemeProvider } from 'baseui';

const routes = [
  { path: '/', element: <HomePage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'reset-password/:username/:secret', element: <ResetPasswordPage /> },
  ReentryPortalRoutes,
  DigPortalRoutes,
  AdminPortalRoutes,
  { path: '*', element: <NotFoundPage /> }
];

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const element = useRoutes(routes);

  const getSessionStatus: AsyncStatus = useSelector(state => state.shared.auth.getSessionStatus);
  const authenticatedUser: AuthenticatedUser | null = useSelector(state => state.shared.auth.authenticatedUser);
  const theme: Theme = useSelector(state => state.shared.theme.global);

  useEffect(() => {
    const absolutePath = window.location.href;
    const relativePath = absolutePath.substring(absolutePath.lastIndexOf('/'));

    if(!authenticatedUser) {

      // If not logged in, and request hasn't been sent yet to retrieve session, dispatch action to retrieve session from server
      if (getSessionStatus === AsyncStatus.Idle) {
        dispatch(getSession());

        // If the session request came back unauthorized the user needs to login
      } else if (getSessionStatus === AsyncStatus.Failed) {

        // If the user is on an unprotected page, do nothing
        if (
          ['/login', '/register'].includes(relativePath) ||
          absolutePath.indexOf('reset-password') > -1
        ) return;

        // Otherwise if another URL is hit, redirect to login page 
        navigate('/login');
      }

    }

  }, [getSessionStatus, authenticatedUser, dispatch, navigate]);

  return <ThemeProvider theme={theme}>
    {element}
  </ThemeProvider>;

}

export default App;
