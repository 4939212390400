import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector } from '../../../../../../redux/hooks';
import { font_med } from '../../../../../shared/styles';
import Card from '../../../../../shared/components/elements/Card';
import ProgressGraph, { type ProgressGraphItem } from '../../../../../shared/components/elements/ProgressGraph';
import { formatCurrencyAmount, calculatePaymentBreakdownByCategory, getCategoryColor } from '../../../../utils';
import { FlexGrid, FlexGridItem } from '../../../../../shared/components/layout/FlexGrid';
import { type ReentryAgencyExtendedInfo, type Payment } from 'unity-types';

const CategoryBreakdown = () => {
  const [, $theme] = useStyletron();
  const userAgency: ReentryAgencyExtendedInfo | null = useSelector(state => state.reentry_portal.rp_agencies.userAgency);
  const allPayments: Payment[] | null = useSelector(state => state.reentry_portal.rp_payments.allPayments);
  const [totalPaidByAllClients, setTotalPaidByAllClients] = useState<number>();
  const [data, setData] = useState<ProgressGraphItem[]>();
  
  useEffect(() => {
    if(userAgency && allPayments) {
      const { percentDeposits, percentRent, percentStabilization, totalDeposits, totalRent, totalStabilization, totalPaid } = calculatePaymentBreakdownByCategory(allPayments);
      setTotalPaidByAllClients(totalPaid);
      const data: ProgressGraphItem[] = [
        { id: 'totalDepositsByAllClients', label: 'Deposits', percent: percentDeposits, color: getCategoryColor('Deposit'), amount: `$${formatCurrencyAmount(totalDeposits)}` },
        { id: 'totalRentByAllClients', label: 'Rent', percent: percentRent, color: getCategoryColor('Rent'), amount: `$${formatCurrencyAmount(totalRent)}` },
        { id: 'totalStabilizationByAllClients', label: 'Stabilization', percent: percentStabilization, color: getCategoryColor('Stabilization'), amount: `$${formatCurrencyAmount(totalStabilization)}` },
      ]
      setData(data);
    }
  }, [userAgency, allPayments]);

  return (
    <Card overrides={{ padding: '10px 10px 20px 10px', justifyContent: 'center' }}>
      { data && <ProgressGraph data={data} font={font_med} opacity={.4} labelSpacing={'space-between'} /> }
      <FlexGrid overrides={{ justifyContent: 'center' }}>
        { 
          <FlexGridItem overrides={{ ...font_med, backgroundColor: $theme.colors.white, color: $theme.colors.black, padding: '0px 5px', borderRadius: '5px' }}>
            % Category of Total Paid (${totalPaidByAllClients ? formatCurrencyAmount(totalPaidByAllClients!) : '0.00'})
          </FlexGridItem>
        }
      </FlexGrid>
    </Card>
  )
}

export default CategoryBreakdown;
