import { useAppSelector as useSelector } from '../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { useNavigate } from 'react-router-dom';
import { getAgencyDetailPageUrl, formatCurrencyAmount } from "../../utils";
import { font_lg, font_med } from '../../../shared/styles';
import Card from '../../../shared/components/elements/Card';
import { FlexGrid, FlexGridItem } from '../../../shared/components/layout/FlexGrid';
import { AsyncStatus } from '../../../shared/constants';
import { SmallSpinner } from '../../../shared/components/elements/Spinner';
import { type DigAgencyExtendedInfo } from 'unity-types';

export const AgencyDashboard = () => {
  const [, $theme] = useStyletron();
  const navigate = useNavigate();
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.dp_agencies.userAgency);
  const getUserAgencyStatus: AsyncStatus = useSelector(state => state.dig_portal.dp_agencies.userAgencyStatus);

  return (
    userAgency &&
    <Card 
      overrides={{ padding: '10px', backgroundColor: $theme.colors.white, borderColor: $theme.colors.accent, ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' } }}
      onClick={() => navigate(getAgencyDetailPageUrl(userAgency.id))}
    >
      <FlexGridItem overrides={{ ...font_lg, color: $theme.colors.accent }}>
        {userAgency.name}
      </FlexGridItem>
      <FlexGridItem overrides={{ ...font_med, color: $theme.colors.black }}>
        {userAgency.community}, AK
      </FlexGridItem>
      {
        getUserAgencyStatus !== AsyncStatus.Loading ?
        <FlexGrid overrides={{ justifyContent: 'flex-end' }}>
          <FlexGridItem overrides={{ width: '100%' }}>
            <FlexGrid overrides={{ ...font_med, color: $theme.colors.accent, marginTop: '10px', borderWidth: '2px', borderStyle: 'solid', borderColor: $theme.colors.accent, borderRadius: '5px', padding: '5px', width: 'none', textAlign: 'right' }}>
              <FlexGridItem>Total Award</FlexGridItem>
              <FlexGridItem>${formatCurrencyAmount(userAgency.totalAward)}</FlexGridItem>
            </FlexGrid>
            <FlexGrid overrides={{ ...font_med, color: $theme.colors.negative, marginTop: '5px', borderWidth: '2px', borderStyle: 'solid', borderColor: $theme.colors.negative, borderRadius: '5px', padding: '5px', width: 'none', textAlign: 'right' }}>
              <FlexGridItem>Total Obligated Funds</FlexGridItem>
              <FlexGridItem>${formatCurrencyAmount(userAgency.totalObligatedFunds)}</FlexGridItem>
            </FlexGrid>
            <FlexGrid overrides={{ ...font_med, color: $theme.colors.positive, marginTop: '5px', borderWidth: '2px', borderStyle: 'solid', borderColor: $theme.colors.positive, borderRadius: '5px', padding: '5px', width: 'none', textAlign: 'right' }}>
              <FlexGridItem>Funds Remaining</FlexGridItem>
              <FlexGridItem>${formatCurrencyAmount(userAgency.fundsRemaining)}</FlexGridItem>
            </FlexGrid>
          </FlexGridItem>
        </FlexGrid> : <SmallSpinner />
      }
    </Card>
  )
}

export default AgencyDashboard;