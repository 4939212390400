import { useStyletron } from "baseui";
import { LargeButton, MediumButton } from "./Button";

export type LinkProps = {
  label: string;
  href: string;
  filename?: string;
  onClick?: () => void;
  overrides? : any;
};

export const LargeDownloadLink = ({ label, href, filename, onClick = () => {}, overrides = {} }: LinkProps) => {
  const [, $theme] = useStyletron();
  return (
    <a href={href} download={filename}>
      <LargeButton 
        label={label} 
        onClick={onClick} 
        overrides={{ 
          backgroundColor: $theme.colors.white, 
          color: $theme.colors.accent,
          borderColor: $theme.colors.accent,
          ':hover': {
            backgroundColor: $theme.colors.light_grey
          },
          ...overrides 
        }}
      />
    </a>
  )
}

export const MediumPageLink = ({ label, href, overrides = {} }: LinkProps) => {
  const [, $theme] = useStyletron();
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <MediumButton
        label={label}
        onClick={() => {}}
        overrides={{ 
          backgroundColor: $theme.colors.white, 
          color: $theme.colors.accent,
          borderColor: $theme.colors.accent,
          ':hover': {
            backgroundColor: $theme.colors.light_grey
          },
          ...overrides 
        }}
      />
    </a>
  )
}