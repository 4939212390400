import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { useNavigate } from 'react-router-dom';
import { resetNewPayment, createNewPayment, resetAsyncPaymentState } from '../../../slices/payment/payment.slice';
import { deleteAttachment } from '../../../slices/attachment/attachment.slice';
import { font_med } from '../../../../shared/styles';
import { getPaymentDetailPageUrl } from '../../../utils';
import { AsyncStatus } from '../../../../shared/constants';
import { FormCard as Card } from '../../../../shared/components/elements/Card';
import { PaymentReviewCard } from '../../cards/payments/PaymentReviewCard';
import Button, { SmallWarningButton } from '../../../../shared/components/elements/Button';
import { FlexGrid } from '../../../../shared/components/layout/FlexGrid';
import Title from '../../../../shared/components/elements/Title';
import { FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Badge from '../../../../shared/components/elements/Badge';
import { Spinner } from 'baseui/spinner';
import { NotificationModal } from '../../../../shared/components/elements/Modal';
import { type CreateAttachmentResponseDTO, type CreatePaymentDTO } from 'unity-types';
import { type NewPayment } from '../../../slices/payment/types';

type ReviewFormProps = {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
};

export const ReviewForm = ({ setActiveStep }: ReviewFormProps) => {
  const [, $theme] = useStyletron();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paymentStatus: AsyncStatus = useSelector(state => state.dig_portal.dp_payments.status);
  const error: string | null = useSelector(state => state.dig_portal.dp_payments.error);
  const newPayment: NewPayment = useSelector(state => state.dig_portal.dp_payments.newPayment!);
  const newPaymentRecordId: string | null = useSelector(state => state.dig_portal.dp_payments.newPaymentRecordId);
  const createAttachmentResponse: CreateAttachmentResponseDTO | null = useSelector(state => state.dig_portal.dp_attachments.createAttachmentResponse);
  const attachmentStatus: AsyncStatus = useSelector(state => state.dig_portal.dp_attachments.status);

  const submit = () => {
    const payload: CreatePaymentDTO = {
      notes: newPayment.notes,
      amount: parseFloat(newPayment.amount),
      vendorId: newPayment.payeeSelectOption[0].id,
      category: newPayment.categorySelectOption[0].id,
      clientId: newPayment.clientSelectOption[0].id,
    }
    if(createAttachmentResponse) payload.receiptAttachmentId = createAttachmentResponse.id;
    dispatch(createNewPayment(payload));
  }

  return (
    <>
      <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px'}} >
        <FlexGrid overrides={{ justifyContent: 'space-between' }}>
          <Title title={'Review new Payment'}/>
          <FlexGridItem>
            <SmallWarningButton
              onClick={() => {
                dispatch(resetNewPayment());
                setActiveStep(0);
              }}
              label={'Start Over'}
            />
          </FlexGridItem>
        </FlexGrid>
        <PaymentReviewCard payment={newPayment} />
        {
          (createAttachmentResponse && createAttachmentResponse.filename) ? 
          <Badge content={createAttachmentResponse.filename} onDeleteClick={() => dispatch(deleteAttachment(createAttachmentResponse))}/> : 
          <FlexGrid>
            <FlexGridItem overrides={{ ...font_med, marginTop: '10px', padding: '5px', borderRadius: '5px', backgroundColor: $theme.colors.white, color: $theme.colors.negative }}>
              No Receipt Attached
            </FlexGridItem>
          </FlexGrid>
        }
        <FlexGrid overrides={{ justifyContent: 'space-between' }} >
          <FlexGridItem overrides={{ marginTop: '10px' }}>
            {attachmentStatus === AsyncStatus.Loading && <Spinner />}
          </FlexGridItem>
          <FlexGridItem overrides={{ marginTop: '10px' }}>
            <Button
              onClick={() => submit()}
              label={'Submit'}
              isLoading={paymentStatus === AsyncStatus.Loading}
            />
          </FlexGridItem>
        </FlexGrid>
      </Card>
      <NotificationModal 
        isOpen={!!newPaymentRecordId} 
        message={'Payment recorded successfully.'} 
        onClose={() => navigate(getPaymentDetailPageUrl(newPaymentRecordId!))}
      />
      <NotificationModal 
        isOpen={!!error} 
        message={error} 
        onClose={() => dispatch(resetAsyncPaymentState())}
      />
    </>
  );
}

export default ReviewForm;