import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { font_extra_lg, font_sm_giant } from '../../../../shared/styles';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import { PortalUser } from '../../../slices/user/types';
import { AdminPortalUser, PortalName, ReentryPortalUser, DigPortalUser, UpdateUserPermissionsDTO } from 'unity-types';
import { AsyncStatus } from '../../../../shared/constants';
import { SmallSpinner } from '../../../../shared/components/elements/Spinner';
import Button from '../../../../shared/components/elements/Button';
import { FormModal, NotificationModal } from '../../../../shared/components/elements/Modal';
import ChangePermissionsForm from '../../forms/users/ChangePermissionsForm';
import { clearViewPortalUserState, getPortalUser, resetUpdateUserActivationAsyncState, updateUserActivation } from '../../../slices/user/user.slice';

const ViewPortalUserCard = () => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  const viewPortalUser: { portal: PortalName, user: PortalUser } | null = useSelector(state => state.admin_portal.ap_users.viewPortalUser);
  const getPortalUserStatus: AsyncStatus = useSelector(state => state.admin_portal.ap_users.getPortalUserStatus);
  const updateUserActivationStatus: AsyncStatus = useSelector(state => state.admin_portal.ap_users.updateUserActivationStatus);
  const updateUserActivationError: string | null = useSelector(state => state.admin_portal.ap_users.updateUserActivationError);
  const [changePermissionsModalIsOpen, setChangePermissionsModalIsOpen] = useState<boolean>(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (updateUserActivationStatus === AsyncStatus.Succeeded) setSuccessModalIsOpen(true);
  }, [updateUserActivationStatus]);

  const onClickChangePermissions = () => setChangePermissionsModalIsOpen(true);

  const onClickChangeActivation = () => {
    if(!viewPortalUser) return;

    const dto: UpdateUserPermissionsDTO = {
      isActive: !viewPortalUser.user.isActive,
    };
    const payload = {
      userId: viewPortalUser.user.id,
      roleName: viewPortalUser.portal,
      dto: dto,
    };

    dispatch(updateUserActivation(payload));
  }

  return (
    <>
    { getPortalUserStatus === AsyncStatus.Loading && <SmallSpinner/> }
    {
      viewPortalUser &&
      <>
      { viewPortalUser.portal === PortalName.Admin_Portal && 
        <AdminPortalUserCard 
          user={viewPortalUser.user as AdminPortalUser} 
          onClickChangePermissions={onClickChangePermissions} 
          onClickChangeActivation={onClickChangeActivation}
          updateUserActivationStatus={updateUserActivationStatus}
        /> 
      }
      { viewPortalUser.portal === PortalName.Reentry_Portal && 
        <ReentryPortalUserCard 
          user={viewPortalUser.user as ReentryPortalUser} 
          onClickChangePermissions={onClickChangePermissions} 
          onClickChangeActivation={onClickChangeActivation}
          updateUserActivationStatus={updateUserActivationStatus}
        /> 
      }
      { viewPortalUser.portal === PortalName.Dig_Portal && 
        <DigPortalUserCard 
          user={viewPortalUser.user as DigPortalUser} 
          onClickChangePermissions={onClickChangePermissions} 
          onClickChangeActivation={onClickChangeActivation}
          updateUserActivationStatus={updateUserActivationStatus}
        /> 
      }
      <FormModal 
        isOpen={changePermissionsModalIsOpen} 
        setIsOpen={setChangePermissionsModalIsOpen}
        content={<ChangePermissionsForm setIsOpen={setChangePermissionsModalIsOpen} />}
        overrides={{ backgroundColor: viewPortalUser.portal === PortalName.Reentry_Portal ? $theme.colors.reentry_accent : viewPortalUser.portal === PortalName.Dig_Portal ? $theme.colors.dig_accent : $theme.colors.accent }}
      />
      <NotificationModal 
        isOpen={successModalIsOpen} 
        message={`Successfully ${viewPortalUser.user.isActive ? 'deactivated' : 'activated'} ${viewPortalUser.user.fullName} for "${viewPortalUser.portal}".`} 
        onClose={() => {
          dispatch(getPortalUser({ portal: viewPortalUser.portal, userId: viewPortalUser.user.id }));
          setSuccessModalIsOpen(false);
          dispatch(resetUpdateUserActivationAsyncState());
          dispatch(clearViewPortalUserState());
        }}
      />
      <NotificationModal 
        isOpen={!!updateUserActivationError} 
        message={updateUserActivationError} 
        onClose={() => dispatch(resetUpdateUserActivationAsyncState())}
      />
      </>
    }
    </>
  );
}

export default ViewPortalUserCard;

type AdminPortalUserCardProps = { 
  user: AdminPortalUser, 
  onClickChangePermissions: () => void, 
  onClickChangeActivation: () => void, 
  updateUserActivationStatus: AsyncStatus 
};

const AdminPortalUserCard = ({ user, onClickChangePermissions, onClickChangeActivation, updateUserActivationStatus }: AdminPortalUserCardProps) => {
  const [, $theme] = useStyletron();
  return (
    <Card overrides={{ padding: '10px', width: 'auto', borderColor: $theme.colors.accent, justifyContent: 'space-between' }}>
      <FlexGrid overrides={{ flexDirection: 'column', rowGap: '0px' }}>
        <FlexGridItem overrides={{ ...font_extra_lg, backgroundColor: $theme.colors.accent, color: $theme.colors.white, padding: '0px 5px', borderRadius: '5px' }}>
          Admin Portal User
        </FlexGridItem>
        <FlexGridItem overrides={{ ...font_sm_giant, color: $theme.colors.accent }}>
          {user.permissionSet}
        </FlexGridItem>
        <FlexGridItem overrides={{ ...font_extra_lg, color: user.isActive ? $theme.colors.positive : $theme.colors.negative }}>
          {user.isActive ? 'Active' : 'Inactive'}
        </FlexGridItem>
      </FlexGrid>
      <FlexGrid overrides={{ flexDirection: 'column', rowGap: '0px' }}>
        <Button 
          label={'Change Permissions'} 
          onClick={onClickChangePermissions}
        />
        <Button 
          label={user.isActive ? 'Deactivate' : 'Activate'} 
          onClick={onClickChangeActivation}
          isLoading={updateUserActivationStatus === AsyncStatus.Loading}
          overrides={{
            marginTop: '5px',
            backgroundColor: user.isActive ? $theme.colors.negative : $theme.colors.positive,
            ':hover': {
              backgroundColor: $theme.colors.white,
              color: user.isActive ? $theme.colors.negative : $theme.colors.positive,
              borderColor: user.isActive ? $theme.colors.negative : $theme.colors.positive
            }
          }}
        />
      </FlexGrid>
    </Card>
  );
}

type ReentryPortalUserCardProps = { 
  user: ReentryPortalUser, 
  onClickChangePermissions: () => void, 
  onClickChangeActivation: () => void, 
  updateUserActivationStatus: AsyncStatus 
};

const ReentryPortalUserCard = ({ user, onClickChangePermissions, onClickChangeActivation, updateUserActivationStatus }: ReentryPortalUserCardProps) => {
  const [, $theme] = useStyletron();
  return (
    <Card overrides={{ padding: '10px', width: 'auto', borderColor: $theme.colors.reentry_accent, justifyContent: 'space-between' }}>
      <FlexGrid overrides={{ flexDirection: 'column', rowGap: '0px' }}>
        <FlexGridItem overrides={{ ...font_extra_lg, backgroundColor: $theme.colors.reentry_accent, color: $theme.colors.white, padding: '0px 5px', borderRadius: '5px' }}>
          Re-entry Portal User
        </FlexGridItem>
        <FlexGridItem overrides={{ ...font_sm_giant, color: $theme.colors.reentry_accent }}>
          {user.permissionSet}
        </FlexGridItem>
        <FlexGridItem overrides={{ ...font_extra_lg, color: user.isActive ? $theme.colors.positive : $theme.colors.negative }}>
          {user.isActive ? 'Active' : 'Inactive'}
        </FlexGridItem>
      </FlexGrid>
      <FlexGrid overrides={{ flexDirection: 'column', rowGap: '0px' }}>
        <Button 
          label={'Change Permissions'} 
          onClick={onClickChangePermissions} 
          overrides={{ 
            backgroundColor: $theme.colors.reentry_accent, 
            ':hover': { 
              backgroundColor: $theme.colors.white,
              color: $theme.colors.reentry_accent,
              borderColor: $theme.colors.reentry_accent
            } 
          }}
        />
        <Button 
          label={user.isActive ? 'Deactivate' : 'Activate'} 
          onClick={onClickChangeActivation}
          isLoading={updateUserActivationStatus === AsyncStatus.Loading}
          overrides={{
            marginTop: '5px',
            backgroundColor: user.isActive ? $theme.colors.negative : $theme.colors.positive,
            ':hover': {
              backgroundColor: $theme.colors.white,
              color: user.isActive ? $theme.colors.negative : $theme.colors.positive,
              borderColor: user.isActive ? $theme.colors.negative : $theme.colors.positive
            }
          }}
        />
      </FlexGrid>
    </Card>
  );
}

type DigPortalUserCardProps = { 
  user: DigPortalUser, 
  onClickChangePermissions: () => void, 
  onClickChangeActivation: () => void, 
  updateUserActivationStatus: AsyncStatus 
};

const DigPortalUserCard = ({ user, onClickChangePermissions, onClickChangeActivation, updateUserActivationStatus }: DigPortalUserCardProps) => {
  const [, $theme] = useStyletron();
  return (
    <Card overrides={{ padding: '10px', width: 'auto', borderColor: $theme.colors.dig_accent, justifyContent: 'space-between' }}>
      <FlexGrid overrides={{ flexDirection: 'column', rowGap: '0px' }}>
        <FlexGridItem overrides={{ ...font_extra_lg, backgroundColor: $theme.colors.dig_accent, color: $theme.colors.white, padding: '0px 5px', borderRadius: '5px' }}>
          DIG Portal User
        </FlexGridItem>
        <FlexGridItem overrides={{ ...font_sm_giant, color: $theme.colors.dig_accent }}>
          {user.permissionSet}
        </FlexGridItem>
        <FlexGridItem overrides={{ ...font_extra_lg, color: user.isActive ? $theme.colors.positive : $theme.colors.negative }}>
          {user.isActive ? 'Active' : 'Inactive'}
        </FlexGridItem>
      </FlexGrid>
      <FlexGrid overrides={{ flexDirection: 'column', rowGap: '0px' }}>
        <Button 
          label={'Change Permissions'} 
          onClick={onClickChangePermissions} 
          overrides={{ 
            backgroundColor: $theme.colors.dig_accent, 
            ':hover': { 
              backgroundColor: $theme.colors.white,
              color: $theme.colors.dig_accent,
              borderColor: $theme.colors.dig_accent
            } 
          }}
        />
        <Button 
          label={user.isActive ? 'Deactivate' : 'Activate'} 
          onClick={onClickChangeActivation}
          isLoading={updateUserActivationStatus === AsyncStatus.Loading}
          overrides={{
            marginTop: '5px',
            backgroundColor: user.isActive ? $theme.colors.negative : $theme.colors.positive,
            ':hover': {
              backgroundColor: $theme.colors.white,
              color: user.isActive ? $theme.colors.negative : $theme.colors.positive,
              borderColor: user.isActive ? $theme.colors.negative : $theme.colors.positive
            }
          }}
        />
      </FlexGrid>
    </Card>
  );
}