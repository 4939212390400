import { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { clearUpdateClient, clearViewClient, updateClient as submitUpdateClient, resetUpdateClientAsyncState, getAllClients,
  clearAllClients } from '../../../slices/client/client.slice';
import { getClientDetailPageUrl } from '../../../utils';
import { AsyncStatus } from '../../../../shared/constants';
import { FormCard as Card } from '../../../../shared/components/elements/Card';
import { ClientReviewUpdateCard } from '../../cards/clients/ClientReviewCard';
import Button, { SmallWarningButton } from '../../../../shared/components/elements/Button';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Title from '../../../../shared/components/elements/Title';
import { NotificationModal } from '../../../../shared/components/elements/Modal';
import { type UpdateClientDTO, type DigAgencyExtendedInfo } from 'unity-types';
import { type StagedUpdateClient } from '../../../slices/client/types';

type ReviewUpdateFormProps = {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
};

export const ReviewUpdateForm = ({ setActiveStep }: ReviewUpdateFormProps) => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAgency: DigAgencyExtendedInfo = useSelector(state => state.dig_portal.dp_agencies.userAgency!);
  const updateClient: StagedUpdateClient = useSelector(state => state.dig_portal.dp_clients.updateClient!);
  const updateRecordId: string = useSelector(state => state.dig_portal.dp_clients.updateRecordId!);
  const updateClientStatus: AsyncStatus = useSelector(state => state.dig_portal.dp_clients.updateClientStatus);
  const updateClientError: string | null = useSelector(state => state.dig_portal.dp_clients.updateClientError);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);

  useEffect(() => {
    if(updateClientStatus === AsyncStatus.Succeeded && !successModalIsOpen) setSuccessModalIsOpen(true);
  }, [updateClientStatus, successModalIsOpen]);

  const isUpdated = () => {
    let isUpdated = false;
    for(const fieldName in updateClient) {
      const field = updateClient[fieldName as keyof StagedUpdateClient];
      if(Array.isArray(field.next) && Array.isArray(field.current)) {
        if(field.next[0].id !== field.current[0].id) isUpdated = true;
      } else {
        if(field.next !== field.current) isUpdated = true;
      }
    }
    return isUpdated;
  };

  const submit = () => {

    const dto: UpdateClientDTO = {};
    if(updateClient.firstName.next !== updateClient.firstName.current) dto.firstName = updateClient.firstName.next;
    if(updateClient.lastName.next !== updateClient.lastName.current) dto.lastName = updateClient.lastName.next;
    if(updateClient.dateOfBirth.next !== updateClient.dateOfBirth.current) dto.dateOfBirth = updateClient.dateOfBirth.next;
    if(updateClient.emailAddress.next !== updateClient.emailAddress.current) dto.emailAddress = updateClient.emailAddress.next;
    if(updateClient.phoneNumber.next !== updateClient.phoneNumber.current) dto.phoneNumber = updateClient.phoneNumber.next;
    if(updateClient.street.next !== updateClient.street.current) dto.street = updateClient.street.next;
    if(updateClient.city.next !== updateClient.city.current) dto.city = updateClient.city.next;
    if(updateClient.state.next !== updateClient.state.current) dto.state = updateClient.state.next;
    if(updateClient.zipCode.next !== updateClient.zipCode.current) dto.zipCode = updateClient.zipCode.next;
    if(updateClient.race.next[0].id !== updateClient.race.current[0].id) {
      dto.race = updateClient.race.next[0].id
    }
    if(updateClient.ethnicity.next[0].id !== updateClient.ethnicity.current[0].id) {
      dto.ethnicity = updateClient.ethnicity.next[0].id
    }
    if(updateClient.gender.next[0].id !== updateClient.gender.current[0].id) {
      dto.gender = updateClient.gender.next[0].id
    }
    if(updateClient.householdMembers.next[0].id !== updateClient.householdMembers.current[0].id) {
      dto.householdMembers = updateClient.householdMembers.next[0].id
    }
    if(updateClient.householdMinors.next[0].id !== updateClient.householdMinors.current[0].id) {
      dto.householdMinors = updateClient.householdMinors.next[0].id
    }

    const payload = {
      id: updateRecordId,
      dto: dto
    }

    dispatch(submitUpdateClient(payload));
  }

  return (
    <>
      <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px' }} >
        <FlexGrid overrides={{ justifyContent: 'space-between' }}>
          <Title title={'Review Client Updates'}/>
          <FlexGridItem>
            <SmallWarningButton
              onClick={() => {
                dispatch(clearUpdateClient());
                dispatch(clearViewClient());
                setActiveStep(0);
              }}
              label={'Start Over'}
            />
          </FlexGridItem>
        </FlexGrid>
        <ClientReviewUpdateCard client={updateClient} isUpdated={isUpdated()}/>
        <FlexGrid overrides={{ justifyContent: 'flex-end' }} >
          <FlexGridItem overrides={{ marginTop: '10px' }}>
            <Button
              onClick={() => submit()}
              label={'Submit'}
              disabled={!isUpdated()}
              isLoading={updateClientStatus === AsyncStatus.Loading}
            />
          </FlexGridItem>
        </FlexGrid>
      </Card>
      <NotificationModal 
        isOpen={successModalIsOpen} 
        message={'Client updated successfully.'} 
        onClose={() => {
          dispatch(clearUpdateClient());
          dispatch(clearViewClient());
          dispatch(resetUpdateClientAsyncState());
          dispatch(clearAllClients());
          dispatch(getAllClients({ agencyId: userAgency.id }));
          navigate(getClientDetailPageUrl(updateRecordId))
        }}
      />
      
      <NotificationModal 
        isOpen={!!updateClientError} 
        message={updateClientError} 
        onClose={() => dispatch(resetUpdateClientAsyncState())}
      />
    </>
  );
}

export default ReviewUpdateForm;