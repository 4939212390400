import { useState, useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { buildNewClient } from '../../../slices/client/client.slice';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import ProgressSteps from '../../../../shared/components/elements/ProgressSteps';
import ContactInfoForm from '../../forms/clients/ContactInfoForm';
import AddressForm from '../../forms/clients/AddressForm';
import DemographicsForm from '../../forms/clients/DemographicsForm';
import ReviewForm from '../../forms/clients/ReviewForm';
import { type NewClient } from '../../../slices/client/types';
import { type ProgressStep } from '../../../../shared/types';

const NewClientPage = () => {
  const dispatch = useDispatch();
  const newClient: NewClient | null = useSelector(state => state.dig_portal.dp_clients.newClient);
  const[activeStep, setActiveStep] = useState<number>(0);
  const [isValidStep, setIsValidStep] = useState<boolean>(true);

  useEffect(() => {
    if(!newClient) {
      dispatch(buildNewClient());
    }
  }, [newClient, dispatch])

  const steps: ProgressStep[] = [{
    title: 'Contact Info',
    component: <ContactInfoForm setIsValidStep={setIsValidStep} />
  }, {
    title: 'Address',
    component: <AddressForm setIsValidStep={setIsValidStep} />
  }, {
    title: 'Demographics',
    component: <DemographicsForm setIsValidStep={setIsValidStep} />
  }, {
    title: 'Review',
    component: <ReviewForm setActiveStep={setActiveStep} />
  }];

  const setPreviousStep = () => {
    if(activeStep <= 0) return;
    setActiveStep(activeStep - 1);
  }

  const setNextStep = () => {
    if(activeStep >= steps.length - 1) return;
    setActiveStep(activeStep + 1);
  }

  return (
    <StyledContainer>
      <FlexGrid overrides={{ justifyContent: 'center', marginTop: '20px' }}>
        <FlexGridItem overrides={{ width: '280px' }}>
        <ProgressSteps steps={steps} activeStep={activeStep} setPreviousStep={setPreviousStep} setNextStep={setNextStep} isValidStep={isValidStep}/>
        </FlexGridItem>
        <FlexGridItem overrides={{ width: '30%' }}>
          {newClient && steps[activeStep].component}
        </FlexGridItem>
      </FlexGrid>
    </StyledContainer>
  );
}

export default NewClientPage;
