import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { font_med_lg, font_med } from '../../../../shared/styles';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import { type DigAgencyExtendedInfo } from 'unity-types';

const AgencyHeaderCard = () => {
  const [, $theme] = useStyletron();
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.dp_agencies.userAgency);

  const StatisticContainer = ({ value, label, color }: { value: number, label: string, color: string }) => {
    return (
      <FlexGrid overrides={{ flexDirection: 'row', alignItems: 'center', color: $theme.colors.white, width: 'auto', rowGap: '0px' }}>
        <FlexGridItem overrides={{ ...font_med_lg, backgroundColor: $theme.colors.white, color: color, lineHeight: '30px', padding: '5px', borderRadius: '5px', marginRight: '-16px' }}>{value}</FlexGridItem>
        <FlexGridItem overrides={{ ...font_med, backgroundColor: $theme.colors.white, color: color, padding: '5px', borderRadius: '5px' }}>{label}</FlexGridItem>
      </FlexGrid>
    )
  }

  return (
    userAgency &&
    <Card overrides={{ padding: '0px 10px' }}>
      <FlexGridItem overrides={{ ...font_med_lg, color: $theme.colors.accent }}>{userAgency.name}</FlexGridItem>
      <FlexGridItem overrides={{ ...font_med }}>{userAgency.community}, AK</FlexGridItem>
      <FlexGrid overrides={{ justifyContent: 'flex-start', margin: '10px', rowGap: '0px' }}>
        <StatisticContainer value={userAgency.clients} label={'Clients'} color={$theme.colors.accent}/>
        <StatisticContainer value={userAgency.payments} label={'Payments'} color={$theme.colors.accent}/>
      </FlexGrid>
    </Card>
  )
}

export default AgencyHeaderCard;
