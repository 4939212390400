import { useState, useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { buildNewPayment } from '../../../slices/payment/payment.slice';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import ProgressSteps from '../../../../shared/components/elements/ProgressSteps';
import PaymentDetailsForm from '../../forms/payments/PaymentDetailsForm';
import ReviewForm from '../../forms/payments/ReviewForm';
import DocumentUploadForm from '../../forms/AttachmentUploadForm';
import { type NewPayment } from '../../../slices/payment/types';
import { type ProgressStep } from '../../../../shared/types';

const InputPaymentPage = () => {
  const dispatch = useDispatch();
  const newPayment: NewPayment | null = useSelector(state => state.reentry_portal.rp_payments.newPayment);
  const[activeStep, setActiveStep] = useState<number>(0);
  const [isValidStep, setIsValidStep] = useState<boolean>(true);

  useEffect(() => {
    if(newPayment === null) {
      dispatch(buildNewPayment());
    }
  }, [newPayment, dispatch])

  const steps: ProgressStep[] = [{
    title: 'Details',
    component: <PaymentDetailsForm setIsValidStep={setIsValidStep} />
  }, {
    title: 'Upload Receipt',
    component: <DocumentUploadForm title={'Please upload a receipt'} type={'Receipt'} />
  }, {
    title: 'Review',
    component: <ReviewForm setActiveStep={setActiveStep} />
  }];

  const setPreviousStep = () => {
    if(activeStep <= 0) return;
    setActiveStep(activeStep - 1);
  }

  const setNextStep = () => {
    if(activeStep >= steps.length - 1) return;
    setActiveStep(activeStep + 1);
  }

  return (
    <StyledContainer>
      <FlexGrid overrides={{ justifyContent: 'center', marginTop: '20px' }}>
        <FlexGridItem overrides={{ width: '280px' }}>
          <ProgressSteps steps={steps} activeStep={activeStep} setPreviousStep={setPreviousStep} setNextStep={setNextStep} isValidStep={isValidStep}/>
        </FlexGridItem>
        <FlexGridItem overrides={{ width: '30%' }}>
          {newPayment && steps[activeStep].component}
        </FlexGridItem>
      </FlexGrid>
    </StyledContainer>
  );
}

export default InputPaymentPage;
