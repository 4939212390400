import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useNavigate } from 'react-router-dom';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { clearState, generateReport, getReportTypes } from '../../../slices/reports/reports.slice';
import { AsyncStatus } from '../../../../shared/constants';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { LargeButton } from '../../../../shared/components/elements/Button';
import { NotificationModal } from '../../../../shared/components/elements/Modal';
import Select from '../../../../shared/components/elements/Select';
import { SelectOption } from '../../../../shared/types';
import Card from '../../../../shared/components/elements/Card';
import { font_sm_giant, notification_font } from '../../../../shared/styles';
import { LargeDownloadLink } from '../../../../shared/components/elements/Link';
import { generateDateString } from '../../../../shared/utils';
import { PortalName, ReportTypes } from 'unity-types';

const GenerateReportForm = () => {
  const [css, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status: AsyncStatus = useSelector(state => state.admin_portal.ap_reports.status);
  const error: string | null = useSelector(state => state.admin_portal.ap_reports.error);
  const link: string | null = useSelector(state => state.admin_portal.ap_reports.link);
  const reportTypes: ReportTypes | null = useSelector(state => state.admin_portal.ap_reports.reportTypes);
  const [selectedSchema, setSelectedSchema] = useState<SelectOption[]>([]);
  const [selectedView, setSelectedView] = useState<SelectOption[]>([]);

  useEffect(() => {
    if(reportTypes === null) dispatch(getReportTypes());
  }, [dispatch, reportTypes]);

  useEffect(() => {
    if(selectedSchema.length > 0) buildReportTypeOptions();
  }, [selectedSchema]);

  const buildPortalOptions = () => {
    if(!reportTypes) return [];
    const portals = Object.keys(reportTypes);
    return portals.map(portal => ({ id: portal, label: portal }));
  }

  const buildReportTypeOptions = () => {
    if(!reportTypes || !selectedSchema.length) return [];
    const portal = selectedSchema[0].id as PortalName;
    const reportTypesForPortal = reportTypes[portal];
    if(!reportTypesForPortal) return [];
    return reportTypesForPortal.map(reportType => ({ id: reportType, label: reportType }));
  }

  return (
    <FlexGrid overrides={{ width: 'auto', height: '640px'}}>
      <Card overrides={{ backgroundColor: 'transparent', border: 'none' }}>
        <FlexGrid overrides={{ marginBottom: '20px' }}>
          <FlexGridItem overrides={{ textAlign: 'left', alignItems: 'center' }}>
            <div className={css({ ...notification_font, color: $theme.colors.accent, textAlign: 'left', alignItems: 'center' })}>Generate</div>
            <div className={css({ ...font_sm_giant, color: $theme.colors.accent, textAlign: 'left', alignItems: 'center' })}>Report</div>
          </FlexGridItem>
        </FlexGrid>
        <FlexGrid overrides={{ justifyContent: 'center' }}>
          <FlexGridItem overrides={{ width: '400px' }}>
            <FlexGridItem>
              <Select
                label={'Select Portal:'}
                labelOverrides={{ ...notification_font, color: $theme.colors.accent }}
                value={selectedSchema}
                onChange={params => setSelectedSchema(params.value as SelectOption[])}
                options={buildPortalOptions()}
                rootOverrides={{
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderRadius: '8px',
                  borderColor: $theme.colors.accent,
                }}
              />
            </FlexGridItem>
            <FlexGridItem overrides={{ display: selectedSchema.length > 0 ? 'visible': 'none', marginBottom: '20px' }}>
              <Select
                label={'Select Report Type:'}
                labelOverrides={{ ...notification_font, color: $theme.colors.accent }}
                value={selectedView}
                onChange={params => setSelectedView(params.value as SelectOption[])}
                options={buildReportTypeOptions()}
                rootOverrides={{
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderRadius: '8px',
                  borderColor: $theme.colors.accent,
                }}
              />
            </FlexGridItem>
            <FlexGridItem overrides={{ display: selectedSchema.length > 0 && selectedView.length > 0 ? 'visible': 'none', marginBottom: '20px' }}>
              <LargeButton 
                label={'Generate Report'}
                onClick={() => {
                  const schema = selectedSchema[0].id as PortalName;
                  const view = selectedView[0].id;
                  dispatch(generateReport({ schema, view }))}
                }
                isLoading={status === AsyncStatus.Loading}
              />
            </FlexGridItem>
            {
              (link && selectedSchema.length > 0 && selectedView.length > 0) &&
              <FlexGridItem overrides={{ marginBottom: '40px' }}>
                <LargeDownloadLink 
                  label={'Download Report'} 
                  href={link} 
                  filename={`${selectedSchema[0].id}.${selectedView[0].id}_${generateDateString()}.csv`}
                  onClick={() => navigate('/admin-portal')}
                />
              </FlexGridItem>
            }
          </FlexGridItem>
        </FlexGrid>
      </Card>
      <NotificationModal 
        isOpen={!!error} 
        message={error} 
        onClose={() => dispatch(clearState())}
      />
    </FlexGrid>
  );
}

export default GenerateReportForm;