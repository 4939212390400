import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import ExtraLargeSpinner from '../../../../shared/components/elements/Spinner';
import UserList from '../../lists/users/UserList';
import { type UnityUser } from 'unity-types';

const UsersHomePage = () => {
  const allUnityUsers: UnityUser[] | null = useSelector(state => state.admin_portal.ap_users.allUnityUsers);

  return (
    <StyledContainer>
        {
          allUnityUsers ?
          <FlexGrid overrides={{ justifyContent: 'flex-start', paddingLeft: '10px' }}>
            <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '1500px' }}>
              <UserList/>
            </FlexGridItem>
          </FlexGrid>
          : <ExtraLargeSpinner/>
        }
    </StyledContainer>
  );

}

export default UsersHomePage;
