import { useAppSelector as useSelector } from "../../../../../redux/hooks";
import { useStyletron } from "baseui";
import { useNavigate } from "react-router-dom";
import { font_med, font_lg } from "../../../../shared/styles";
import { getUserDetailPageUrl } from "../../../utils";
import { AsyncStatus } from "../../../../shared/constants";
import Card from "../../../../shared/components/elements/Card";
import { FlexGrid, FlexGridItem } from "../../../../shared/components/layout/FlexGrid";
import { MediumSpinner } from "../../../../shared/components/elements/Spinner";
import { type UnityUser, PortalName } from 'unity-types';

const UserList = () => {
  const [css, $theme] = useStyletron();
  const allUnityUsers: UnityUser[] | null = useSelector(state => state.admin_portal.ap_users.allUnityUsers);
  const getAllUnityUsersStatus: AsyncStatus = useSelector(state => state.admin_portal.ap_users.getAllUnityUsersStatus);

  return (
    <Card overrides={{ padding: '10px', backgroundColor: $theme.colors.accent, borderColor: $theme.colors.accent, width: '100%' }}>
      <FlexGrid overrides={{ flexDirection: 'column'}}>
        <FlexGridItem>
          <div className={css({...font_lg, color: $theme.colors.white, textAlign: 'center', margin: '-10px' })}>All Users</div>
        </FlexGridItem>
        <Card 
          overrides={{ 
            backgroundColor: $theme.colors.white, 
            width: 'auto', 
            borderColor: $theme.colors.white, 
            height: `${window.innerHeight - 340}px`, 
            overflow: 'auto',
            padding: '10px 5px 10px 5px',
            scrollbarColor: `${$theme.colors.accent} ${$theme.colors.white}`,
            scrollbarWidth: 'none'
          }} 
        >
        <FlexGrid overrides={{ flexDirection: 'column', rowGap: '5px', justifyContent: 'center' }}>
          {
            getAllUnityUsersStatus === AsyncStatus.Loading ?
            <MediumSpinner /> :
            allUnityUsers && allUnityUsers.map((unityUser, i) => <UnityUserListItem unityUser={unityUser} key={i} />)
          }
        </FlexGrid>
        </Card>
      </FlexGrid>
    </Card>
  )
}

export default UserList;

export type UnityUserListItemProps = {
  unityUser: UnityUser;
}

const UnityUserListItem = ({ unityUser }: UnityUserListItemProps) => {
  const [, $theme] = useStyletron();
  const navigate = useNavigate();
  return (
    <Card overrides={{ padding:'5px', ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' } }} onClick={() => navigate(getUserDetailPageUrl(unityUser.id))}>
      <FlexGrid overrides={{ ...font_med, flexDirection: 'row', rowGap: '0px', justifyContent: 'space-between', alignItems: 'center' }}>
        <FlexGrid overrides={{ justifyContent: 'flex-start', width: '75%' }}>
          <FlexGridItem overrides={{...font_med, color: $theme.colors.black }}>{unityUser.firstName} {unityUser.lastName}</FlexGridItem>
          <FlexGridItem overrides={{...font_med, color: $theme.colors.accent }}>{unityUser.username}</FlexGridItem>
          <FlexGridItem overrides={{...font_med, color: $theme.colors.grey }}>{unityUser.agencyName}</FlexGridItem>
        </FlexGrid>
        <FlexGrid overrides={{ justifyContent: 'flex-end', columnGap: '10px', width: '25%', marginRight: '5px' }}>
          { unityUser.roles.includes(PortalName.Reentry_Portal) && <FlexGridItem overrides={{ backgroundColor: $theme.colors.reentry_accent, width: '25px', height: '25px', borderRadius: '50%' }}></FlexGridItem> }
          { unityUser.roles.includes(PortalName.Dig_Portal) && <FlexGridItem overrides={{ backgroundColor: $theme.colors.dig_accent, width: '25px', height: '25px', borderRadius: '50%' }}></FlexGridItem> }
          { unityUser.roles.includes(PortalName.Admin_Portal) && <FlexGridItem overrides={{ backgroundColor: $theme.colors.accent, width: '25px', height: '25px', borderRadius: '50%' }}></FlexGridItem> }
        </FlexGrid>
      </FlexGrid>
    </Card>
  )
}
