import { useAppSelector as useSelector } from "../../../../../redux/hooks";
import { useStyletron } from "baseui";
import { useNavigate } from "react-router-dom";
import { font_med, font_lg, font_sm } from "../../../../shared/styles";
import { getUserDetailPageUrl } from "../../../utils";
import { AsyncStatus } from "../../../../shared/constants";
import Card from "../../../../shared/components/elements/Card";
import { FlexGrid, FlexGridItem } from "../../../../shared/components/layout/FlexGrid";
import { MediumSpinner } from "../../../../shared/components/elements/Spinner";
import { type DigPortalUser } from 'unity-types';

export const UserList = () => {
  const [css, $theme] = useStyletron();
  const allUsers: DigPortalUser[] | null = useSelector(state => state.dig_portal.dp_users.allUsers);
  const userStatus: AsyncStatus = useSelector(state => state.dig_portal.dp_users.status);

  return (
    <Card overrides={{ padding: '10px', backgroundColor: $theme.colors.accent, borderColor: $theme.colors.accent, width: '650px' }}>
      <FlexGrid overrides={{ flexDirection: 'column'}}>
        <FlexGridItem>
          <div className={css({...font_lg, color: $theme.colors.white, textAlign: 'center', margin: '-10px' })}>Navigators</div>
        </FlexGridItem>
        <Card 
          overrides={{ 
            backgroundColor: $theme.colors.white, 
            width: 'auto', 
            borderColor: $theme.colors.white, 
            height: `${window.innerHeight - 750}px`, 
            overflow: 'auto',
            padding: '10px 5px 10px 5px',
            scrollbarColor: `${$theme.colors.accent} ${$theme.colors.white}`,
            scrollbarWidth: 'none'
          }} 
        >
        <FlexGrid overrides={{ flexDirection: 'column', rowGap: '5px', justifyContent: 'center' }}>
          {
            userStatus === AsyncStatus.Loading ?
            <MediumSpinner /> :
            allUsers && allUsers.map((user, i) => <UserListItem user={user} key={i} />)
          }
        </FlexGrid>
        </Card>
      </FlexGrid>
    </Card>
  )
}

export type UserListItemProps = {
  user: DigPortalUser;
}

const UserListItem = ({ user }: UserListItemProps) => {
  const [, $theme] = useStyletron();
  const navigate = useNavigate();
  return (
    <Card overrides={{ padding:'5px', ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' } }} onClick={() => navigate(getUserDetailPageUrl(user.id))}>
      <FlexGrid overrides={{ ...font_sm, color: $theme.colors.accent, flexDirection: 'column', rowGap: '0px', justifyContent: 'space-between' }}>
        <FlexGridItem overrides={{...font_med, color: $theme.colors.accent }}>{user.firstName} {user.lastName}</FlexGridItem>
        <FlexGridItem overrides={{...font_sm, color: $theme.colors.black }}>{user.username}</FlexGridItem>
      </FlexGrid>
    </Card>
  )
}
