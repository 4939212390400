import { useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useParams } from 'react-router-dom';
import { getUser } from '../../../slices/user/user.slice';
import { getAllPaymentsByUser } from '../../../slices/payment/payment.slice';
import { getAllVendorsByUser } from '../../../slices/vendor/vendor.slice';
import StandAlonePageContainer from '../../../../shared/components/layout/StandAlonePageContainer';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { BlankContainer } from '../../../../shared/components/layout/StyledContainer';
import UserHeaderCard from '../../cards/users/UserHeaderCard';
import UserClientList from '../../lists/clients/UserClientList';
import UserPaymentList from '../../lists/payments/UserPaymentList';
import UserVendorList from '../../lists/vendors/UserVendorList';
import ExtraLargeSpinner from '../../../../shared/components/elements/Spinner';
import { type ReentryAgencyExtendedInfo, type ReentryPortalUser, type Payment, type Vendor } from 'unity-types';

const UserDetailPage = () => {
  const { recordId } = useParams();
  const dispatch = useDispatch();
  const userAgency: ReentryAgencyExtendedInfo | null = useSelector(state => state.reentry_portal.rp_agencies.userAgency);
  const viewUser: ReentryPortalUser | null = useSelector(state => state.reentry_portal.rp_users.viewUser);
  const allPaymentsByUser: Payment[] | null = useSelector(state => state.reentry_portal.rp_payments.allPaymentsByUser);
  const allVendorsByUser: Vendor[] | null = useSelector(state => state.reentry_portal.rp_vendors.allVendorsByUser);

  useEffect(() => {
    if(!viewUser && recordId) dispatch(getUser({ userId: recordId }));
  }, [viewUser, recordId, dispatch]);

  useEffect(() => {
    if(!allPaymentsByUser && viewUser && userAgency) dispatch(getAllPaymentsByUser({ createdByUserId: viewUser.id, agencyId: userAgency.id }))
  }, [allPaymentsByUser, viewUser, userAgency, dispatch]);

  useEffect(() => {
    if(!allVendorsByUser && viewUser) dispatch(getAllVendorsByUser({ createdByUserId: viewUser.id }))
  }, [allVendorsByUser, viewUser, dispatch]);

  return (
    <BlankContainer>
      <StandAlonePageContainer>
        {
          viewUser ?
          <FlexGrid overrides={{ justifyContent: 'flex-start' }}>
            <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '700px' }}>
              <UserHeaderCard />
            </FlexGridItem>
            <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '30%' }}>
              <UserClientList />
            </FlexGridItem>
            <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '40%' }}>
              <UserPaymentList />
            </FlexGridItem>
            <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '30%' }}>
              <UserVendorList />
            </FlexGridItem>
          </FlexGrid> : <FlexGrid overrides={{ margin: '10px' }}><ExtraLargeSpinner/></FlexGrid>
        }
      </StandAlonePageContainer>
    </BlankContainer>
  );
}

export default UserDetailPage;

