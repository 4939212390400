import { useEffect } from "react";
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../../../redux/hooks";
import { font_med } from "../../../../shared/styles";
import { getAttachment } from "../../../slices/attachment/attachment.slice";
import { RecordDetailLinkCard as Card } from '../../../../shared/components/elements/Card';
import { useStyletron } from "baseui";
import { SmallSpinner } from "../../../../shared/components/elements/Spinner";
import { type Attachment } from 'unity-types'
import { FlexGrid } from "../../../../shared/components/layout/FlexGrid";

const ViewAttachmentCard = ({ id, title }: { id: string, title: string }) => {
  const [css, $theme] = useStyletron();
  const dispatch = useDispatch();
  const attachment: Attachment | null = useSelector(state => state.dig_portal.dp_attachments.attachment);

  useEffect(() => {
    if(!attachment) {
      dispatch(getAttachment({ id }));
    }
  }, [attachment, id, dispatch]);

  return (
    attachment
    ?
      <a className={css({ textDecoration: 'none' })} target="_blank" rel="noreferrer" href={attachment.link}>
        <Card overrides={{ backgroundColor: $theme.colors.white, padding: '5px', alignItems: 'center', flexDirection: 'row' }}>
          <FlexGrid overrides={{ ...font_med, color: $theme.colors.accent, justifyContent: 'center' }}>{title}</FlexGrid>
        </Card>
      </a>
    : <SmallSpinner/>
  )
}

export default ViewAttachmentCard;
